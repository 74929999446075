import React, { useState, useEffect, useCallback, useContext } from "react";
import { FaArrowUp } from "react-icons/fa";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
const TermsAndConditions = () => {
  const [showButton, setShowButton] = useState(false);
  // Detect scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      {showButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-5 right-5 p-3 rounded-full shadow-lg hover:opacity-90 transition-opacity"
          style={{
            background: "linear-gradient(to right, #6A5ACD, #00BFFF)", // Gradient color
            color: "white",
            zIndex: 1000,
          }}
        >
          <FaArrowUp size={20} /> {/* FontAwesome arrow up icon */}
        </button>
      )}
      <h1>
        <strong>Terms and Conditions</strong>
      </h1>

      <p>
        Welcome to Skilled Mates! These terms and conditions outline the rules
        and regulations for using the Skilled Mates platform.
      </p>

      <h2>
        <strong>1. Acceptance of Terms</strong>
      </h2>
      <p>
        By accessing or using the Skilled Mates platform, you agree to comply
        with and be bound by these Terms and Conditions. If you do not agree
        with any part of these terms, you must not use our platform.
      </p>

      <h2>
        <strong>2. Platform Description</strong>
      </h2>
      <p>
        Skilled Mates is an online platform that facilitates connections between
        employers and employees. We provide a space where employers can post job
        opportunities and employees can search and apply for jobs. Skilled Mates
        does not act as an employer, employment agency, or financial
        intermediary, and does not participate in any employment decisions.
      </p>

      <h2>
        <strong>3. Legal Compliance</strong>
      </h2>
      <p>
        Employers and employees using Skilled Mates must comply with all
        applicable employment, tax, and privacy laws. Skilled Mates does not
        assume any responsibility for ensuring compliance with such laws by its
        users.
      </p>
      <p>
        <strong>Employers must ensure:</strong>
      </p>
      <ul>
        <li>They comply with local, state, and federal employment laws.</li>
        <li>
          They verify that employees meet the legal requirements for employment,
          including obtaining police checks or clearances where required by law.
        </li>
        <li>
          They comply with tax obligations, including collecting Australian
          Business Numbers (ABN) or Tax File Numbers (TFN) as applicable.
        </li>
      </ul>
      <p>
        Skilled Mates is not responsible for storing or verifying such
        documents. It is the responsibility of the employer to ensure legal
        compliance for any employment decisions they make.
      </p>

      <h2>
        <strong>4. Accuracy of Information Provided</strong>
      </h2>
      <p>
        Users are solely responsible for the accuracy and completeness of the
        information they provide on Skilled Mates. This includes job postings,
        employee profiles, and any other data submitted to the platform.
      </p>
      <p>
        Skilled Mates does not verify the accuracy of any information provided
        by its users and is not liable for any misinformation or
        misrepresentation. Users are responsible for performing their own due
        diligence before entering into any employment agreements.
      </p>

      <h2>
        <strong>5. No Employment Relationship</strong>
      </h2>
      <p>
        Skilled Mates is a platform for facilitating connections between
        employers and employees. We do not employ, hire, or contract users. Any
        employment relationship formed as a result of connections made on the
        Skilled Mates platform is solely between the employer and the employee.
      </p>
      <p>
        Skilled Mates is not involved in setting the terms of employment,
        negotiating compensation, or handling employment disputes. All
        agreements between employers and employees are entered into at their own
        risk.
      </p>

      <h2>
        <strong>6. Prohibited Activities</strong>
      </h2>
      <p>
        Users agree not to use Skilled Mates for any illegal or unauthorized
        purposes, including but not limited to:
      </p>
      <ul>
        <li>Posting misleading or fraudulent information.</li>
        <li>
          Engaging in discriminatory practices based on race, gender, religion,
          or other protected characteristics.
        </li>
        <li>
          Using the platform to facilitate illegal employment or labor
          practices.
        </li>
      </ul>
      <p>
        Any such activities are grounds for immediate termination of your
        account and may result in legal action.
      </p>

      <h2>
        <strong>7. Privacy and Data Protection</strong>
      </h2>
      <p>
        Skilled Mates respects your privacy and is committed to protecting your
        personal information. Our Privacy Policy outlines how we collect, use,
        and protect your data.
      </p>
      <p>
        It is the employer’s responsibility to obtain and verify documents
        directly from the employee.
      </p>

      <h2>
        <strong>8. Limitation of Liability</strong>
      </h2>
      <p>
        To the fullest extent permitted by law, Skilled Mates shall not be
        liable for any indirect, incidental, special, consequential, or punitive
        damages arising out of or related to your use of the platform. This
        includes, but is not limited to, loss of profits, data, or other
        intangible losses.
      </p>
      <p>
        Skilled Mates shall not be responsible for any illegal activities,
        non-compliance with employment laws, or fraudulent practices by its
        users.
      </p>

      <h2>
        <strong>9. Indemnification</strong>
      </h2>
      <p>
        You agree to indemnify, defend, and hold harmless Skilled Mates, its
        affiliates, and their respective officers, directors, employees, and
        agents from any claims, damages, liabilities, losses, or expenses
        arising from your use of the platform, violation of these Terms and
        Conditions, or any illegal or unauthorized activity on the platform.
      </p>

      <h2>
        <strong>9. Indemnification</strong>
      </h2>
      <p>
        You agree to indemnify, defend, and hold harmless Skilled Mates, its
        affiliates, and their respective officers, directors, employees, and
        agents from any claims, damages, liabilities, losses, or expenses
        arising from your use of the platform, violation of these Terms and
        Conditions, or any illegal or unauthorized activity on the platform.
      </p>
      <h2>
        <strong>10. Insurance Responsibility</strong>
      </h2>
      <p>
        Taskers are required to have their own insurance coverage to protect
        against potential risks, including property damage, personal injury, or
        other liabilities that may arise while performing tasks. Owners should
        inquire whether Taskers have adequate insurance coverage before
        accepting their services.
      </p>
      <p>
        Skilled Mates does not provide or cover any insurance for Taskers or
        Owners. Any incidents or damages occurring during the performance of
        tasks are the sole responsibility of the parties involved.
      </p>

      {/* Rest of your terms... */}

      <h2>
        <strong>14. Contact Us</strong>
      </h2>
      <p>
        If you have any questions or concerns regarding these Terms and
        Conditions, please contact us using the feedback button.
      </p>

      <p>
        By using Skilled Mates, you acknowledge that you have read, understood,
        and agree to these Terms and Conditions.
      </p>

      <h2>
        <strong>Task Posting Guidelines</strong>
      </h2>
      <p>
        Skilled Mates requires Task Owners to adhere to the following guidelines
        when posting tasks:
      </p>
      <ul>
        <li>
          <strong>Clear Descriptions:</strong> Owners must provide detailed and
          accurate descriptions of the task, including scope, required skills,
          location, and deadlines.
        </li>
        <li>
          <strong>Prohibited Tasks:</strong> Tasks that violate laws, involve
          unsafe activities, or require personal information such as passwords
          are strictly prohibited.
        </li>
        <li>
          <strong>Fair Compensation:</strong> Task Owners must offer reasonable
          compensation for the effort and complexity of the task.
        </li>
      </ul>

      <h2>
        <strong>Tasker Obligations</strong>
      </h2>
      <p>
        Taskers must meet the following responsibilities when accepting tasks:
      </p>
      <ul>
        <li>
          <strong>Skill Assessment:</strong> Ensure you are qualified and
          capable before offering to complete a task.
        </li>
        <li>
          <strong>Timeliness:</strong> Complete the task within the agreed
          timeframe or communicate any delays to the Owner promptly.
        </li>
        <li>
          <strong>Professional Conduct:</strong> Treat the Owner’s property and
          safety with respect and perform work with care.
        </li>
      </ul>

      <h2>
        <strong>Task Agreement and Payment</strong>
      </h2>
      <ul>
        <li>
          <strong>Agreement Formation:</strong> An agreement is formed directly
          between the Task Owner and Tasker when an offer is accepted. Skilled
          Mates is not a party to this agreement.
        </li>
      </ul>

      <h2>
        <strong>Risk Acknowledgement</strong>
      </h2>
      <ul>
        <li>
          <strong>Assumption of Risk:</strong> Taskers and Owners acknowledge
          the risks involved in tasks, including potential property damage or
          personal injury.
        </li>
        <li>
          <strong>Insurance:</strong> Users are encouraged to obtain insurance
          coverage for tasks involving physical labor, travel, or high-value
          items.
        </li>
      </ul>

      <h2>
        <strong>Task Cancellation Policy</strong>
      </h2>
      <ul>
        <li>
          <strong>Owner-Initiated Cancellation:</strong> Owners may cancel tasks
          before work starts. Frequent cancellations may lead to account review.
        </li>
        <li>
          <strong>Tasker-Initiated Cancellation:</strong> Taskers may withdraw
          from a task but must inform the Owner promptly.
        </li>
        <li>
          <strong>No-Shows:</strong> Failing to show up for a task without
          notice may result in account suspension for Taskers.
        </li>
      </ul>

      {/* <h2><strong>Feedback and Ratings</strong></h2>
      <ul>
        <li><strong>Mutual Ratings:</strong> Task Owners and Taskers are encouraged to leave feedback after completing tasks.</li>
        <li><strong>Prohibited Content:</strong> Feedback must not include offensive language, discrimination, or false information.</li>
        <li><strong>Appeals:</strong> Users can appeal unfair ratings by contacting Skilled Mates support.</li>
      </ul> */}

      <h2>
        <strong>Prohibited Task Activities</strong>
      </h2>
      <ul>
        <li>
          <strong>Conflict of Interest:</strong> Tasks involving confidential or
          proprietary information in violation of policies are prohibited.
        </li>
        <li>
          <strong>Unlawful Activities:</strong> Tasks promoting illegal
          activities, fraud, or harm are strictly forbidden.
        </li>
        <li>
          <strong>Exploitation:</strong> Tasks exploiting vulnerable individuals
          or groups will result in account termination.
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
