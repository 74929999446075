import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const SavePaymentMethod = ({ userId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSavePaymentMethod = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      setMessage("Stripe has not loaded yet.");
      setLoading(false);
      return;
    }

    try {
      // Request a SetupIntent client secret from the backend
      const response = await fetch("/api/create-setup-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId }),
      });
      const { clientSecret } = await response.json();

      // Confirm the card setup
      const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: "Test User", // Replace with real data
            email: "test@example.com", // Replace with real data
          },
        },
      });

      if (error) {
        setMessage(error.message);
      } else {
        setMessage("Payment method saved successfully!");
      }
    } catch (err) {
      setMessage("An error occurred while saving the payment method.");
    }

    setLoading(false);
  };

  return (
    <Elements stripe={stripePromise}>
      <form onSubmit={handleSavePaymentMethod}>
        <CardElement options={{ hidePostalCode: true }} />
        <button type="submit" disabled={loading}>
          {loading ? "Saving..." : "Save Payment Method"}
        </button>
      </form>
      {message && <p>{message}</p>}
    </Elements>
  );
};

export default SavePaymentMethod;
