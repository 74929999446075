import React, { useEffect, useState }  from "react";
import { FaUserCheck, FaBriefcase, FaCheckCircle, FaSyncAlt, FaMapMarkerAlt } from "react-icons/fa";
import howItWorks1 from "../images/how-it-works-1.png"; // Replace with your actual image path
import howItWorks2 from "../images/how-it-works-2.png"; // Replace with your actual image path
import howItWorks3 from "../images/how-it-works-3.png"; // Replace with your actual image path
import howItWorks4 from "../images/how-it-works-4.png"; // Replace with your actual image path
import { Link } from "react-router-dom";
import { 
    FaArrowUp 
  } from "react-icons/fa";


const HowItWorksPage = () => {

    const [showButton, setShowButton] = useState(false);

      // Detect scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="min-h-screen bg-gray-100 p-6">
         {showButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-5 right-5 p-3 rounded-full shadow-lg hover:opacity-90 transition-opacity"
          style={{
            background: "linear-gradient(to right, #6A5ACD, #00BFFF)", // Gradient color
            color: "white",
            zIndex: 1000,
          }}
        >
          <FaArrowUp size={20} /> {/* FontAwesome arrow up icon */}
        </button>
      )}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">How It Works</h1>
        <p className="text-gray-600">
          Follow these simple steps to get started with Skilled Mates. Whether you're looking for work or need to hire someone, our platform makes it easy!
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Step 1: Sign Up */}
        <Link
              to="/register"
              className="bg-white p-6 rounded-lg shadow-lg text-center hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
            >
        <div className="bg-white rounded-lg shadow-lg p-6 text-center">
          <img
            src={howItWorks1}
            alt="Sign up"
            className="w-full h-50 object-cover mb-4"
          />
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">1. Sign Up</h2>
          <p className="text-gray-600">
            Create a profile with a few simple steps. Whether you're an employer or job seeker, getting started is quick and easy.
          </p>
          <div className="mt-4">
            <FaUserCheck className="text-green-500 text-4xl mx-auto" />
          </div>
        </div>
        </Link>

        {/* Step 2: Browse */}
        <Link
              to="/find-worker"
              className="bg-white p-6 rounded-lg shadow-lg text-center hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105"
            >
        <div className="bg-white rounded-lg shadow-lg p-6 text-center">
          <img
            src={howItWorks2}
            alt="Browse Jobs"
            className="w-full h-48 object-cover mb-4"
          />
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">2. Browse Jobs or Workers</h2>
          <p className="text-gray-600">
            Job seekers can browse available jobs, while employers can view profiles of available workers.
          </p>
          <div className="mt-4">
            <FaBriefcase className="text-blue-500 text-4xl mx-auto" />
          </div>
        </div>
        </Link>

        {/* Step 3: Instant Matches */}
        <div className="bg-white rounded-lg shadow-lg p-6 text-center">
          <img
            src={howItWorks3}
            alt="Instant Matches"
            className="w-full h-48 object-cover mb-4"
          />
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">3. Get Instant Matches</h2>
          <p className="text-gray-600">
            The platform intelligently matches workers and jobs based on your preferences and availability.
          </p>
          <div className="mt-4">
            <FaSyncAlt className="text-purple-500 text-4xl mx-auto" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
        {/* Step 4: Apply or Hire */}
        <div className="bg-white rounded-lg shadow-lg p-6 text-center  max-w-md ">
          <img
            src={howItWorks4}
            alt="Apply or Hire"
            className="w-full h-48 object-cover mb-4"
          />
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">4. Connect via Email or Phone in Minutes</h2>
          <p className="text-gray-600">
            Job seekers can apply for positions instantly, and employers can hire workers quickly through the platform.
          </p>
          <div className="mt-4">
            <FaCheckCircle className="text-blue-500 text-4xl mx-auto" />
          </div>
        </div>
 
      </div>
    </div>
  );
};

export default HowItWorksPage;
