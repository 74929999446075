import React, { useState, useEffect } from "react";
import { BounceLoader } from "react-spinners";
import axiosInstance from "../axiosInstance";
import { FiInfo } from "react-icons/fi";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css"; // Import the styles for the tooltip
import Select from "react-select"; 

const FormForWorker = ({ onSubmit, existingData }) => {
  const [formData, setFormData] = useState({
    name: "",
    job: "",
    phone: "",
    email: "",
    location: "",
    badges: [],
    certificates: [],
    availability: {
      Monday: true,
      Tuesday: true,
      Wednesday: true,
      Thursday: true,
      Friday: true,
      Saturday: true,
      Sunday: true,
    },
    sharePhone: false,
    description: "",
    termsAccepted: false,
    policeCheck: false,
    yearsExperience: 0,
    gender: "",
    userEmail: "",
  });

  const [suburbs, setSuburbs] = useState([]);
  const [jobRoles, setJobRoles] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showJobDropdown, setShowJobDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState(false);

  const [certificates, setCertificates] = useState([]); // State for managing certificate inputs

useEffect(() => {
  if (existingData) {
    setFormData(existingData);
    setFormData((prevData) => ({
      ...prevData,
      termsAccepted: true,
    }));
  }
}, [existingData]);

  useEffect(() => {
    const localUserData = JSON.parse(localStorage.getItem('user'));
    if (localUserData) {
      setFormData(prevFormData => ({
        ...prevFormData,
        name: localUserData.name || '',
        email: localUserData.email || '',
        phone: localUserData.phone || '',
        userEmail: localUserData.email || '',
      }));
    }
  }, []);


  //I want to call the api to get the job roles and use it for the dropdown. I want to get all data at onces and not based on the query.
  useEffect(() => {
    // Fetch job roles from the backend
    const fetchJobRoles = async () => {
      setJobLoading(true);
      try {
        const response = await axiosInstance.get("/api/jobRoles/getRoles");
        setJobRoles(
          response.data.JobRoles.map((role) => ({ value: role, label: role }))
        ); 
      } catch (error) {
        console.error("Error fetching job roles:", error);
      } finally {
        setJobLoading(false);
      }
    };

    fetchJobRoles();
  }, []);

  useEffect(() => {
    const fetchSuburbs = async () => {
      if (formData.location.length > 0) {
        setLoading(true);
        try {
          const response = await axiosInstance.get(`/api/suburbs`, {
            params: { query: formData.location },
          });
          setSuburbs(response.data);
          setShowDropdown(true);
        } catch (error) {
          console.error("Error fetching suburb data:", error);
          setSuburbs([]);
          setShowDropdown(false);
        } finally {
          setLoading(false);
        }
      } else {
        setShowDropdown(false);
      }
    };

    const debounceFetch = setTimeout(() => {
      fetchSuburbs();
    }, 300); // Debounce time in milliseconds

    return () => clearTimeout(debounceFetch); // Clear timeout on unmount
  }, [formData.location]);

  // useEffect(() => {
  // const fetchJobRoles = async () => {
  //   if (formData.job.length > 0) {
  //     setJobLoading(true);
  //     try {
  //       // const response = await axiosInstance.get(`/api/jobRoles/getRoles`, { params: { query: formData.job, limit: 20 } });
  //         const response = await axiosInstance.get(`/api/jobRoles/getRoles`);

  //       setJobRoles(response.data.JobRoles);
  //       setShowJobDropdown(true);
  //     } catch (error) {
  //       console.error('Error fetching job roles:', error);
  //       setJobRoles([]);
  //       setShowJobDropdown(false);
  //     } finally {
  //       setJobLoading(false);
  //     }
  //   } else {
  //     setShowJobDropdown(false);
  //   }
  // };

  //   const debounceFetch = setTimeout(() => {
  //     fetchJobRoles();
  //   }, 300); // Debounce time in milliseconds

  //   return () => clearTimeout(debounceFetch); // Clear timeout on unmount
  // }, [formData.job]);

  const handleInfoClick = () => {
    window.open("/feedback", "_blank", "noopener,noreferrer"); // Open feedback form in a new tab
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      if (name in formData.availability) {
        setFormData((prevData) => ({
          ...prevData,
          availability: {
            ...prevData.availability,
            [name]: checked,
          },
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: checked,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCertificateChange = (index, event) => {
    const newCertificates = [...certificates];
    newCertificates[index] = event.target.value;
    setCertificates(newCertificates);
  };

  const addCertificateField = () => {
    setCertificates([...certificates, ""]);
  };

  const removeCertificateField = (index) => {
    const newCertificates = certificates.filter((_, i) => i !== index);
    setCertificates(newCertificates);
  };

  const validatePhone = (phone) => {
    //check if phone is all numbers and no letter or anything else
    const phoneRegex = /^[0-9]+$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validatePhone(formData.phone)) { 
      alert("Please enter a valid phone number.");
      return;
    }
    if (!validateEmail(formData.email)) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!formData.termsAccepted) {
      alert("You must accept the terms and conditions to proceed.");
      return;
    }

    //remove certificate which is empty or has no letter and has only spaces. for example i want to remove last one ['Certi1', 'Certi2', 'Certi3', '']
    setCertificates(
      certificates.filter((certificate) => certificate.trim().length > 0)
    );

    const submissionData = { ...formData, certificates };
    onSubmit(submissionData);
  };

  const handleSelect = (suburb) => {
    setFormData((prevData) => ({ ...prevData, location: suburb }));
    setShowDropdown(false);
  };

  const handleJobSelect = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      job: selectedOption ? selectedOption.value : "",
    }));
    setShowJobDropdown(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-4 max-w-lg mx-auto bg-white rounded shadow-md"
    >
      <h2 className="text-2xl font-bold mb-4">Please fill the details</h2> 
      
      <div className="mb-4 relative">
        <label className="block text-gray-700 font-bold mb-2 flex items-center">
          Role
        </label>
        <p className="mt-2 text-sm italic text-gray-600 bg-yellow-100 p-2  rounded-md">
          <strong>Note:</strong> If the role is not available in the list,
          please fill out the
          <a href="/feedback" className="text-blue-600 hover:underline">
            {" "}
            feedback form
          </a>{" "}
          and we will add it within a few hours.
        </p>

        <Select
          name="job"
          options={jobRoles}
          value={jobRoles.find((option) => option.value === formData.job)}
          onChange={handleJobSelect}
          isLoading={jobLoading}
          placeholder="Start typing..."
          className="w-full mt-2"
          required
        />

        {/* <input
          type="text"
          name="job"
          placeholder="Start typing..."
          value={formData.job}
          onChange={handleChange}
          required
          className="w-full p-2 border rounded"
        />
        {showJobDropdown && (
          <div className="relative">
            <div className="absolute z-10 bg-white border border-gray-300 rounded shadow-lg w-full mt-1 max-h-60 overflow-auto">
              {jobLoading ? (
                <div className="p-2 text-center">
                  <BounceLoader color="#000" />
                </div>
              ) : jobRoles.length > 0 ? (
                jobRoles.map((job, index) => (
                  <div
                    key={index}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleJobSelect(job)}
                  >
                    {job}
                  </div>
                ))
              ) : (
                <div  > </div>
              )}
            </div>
          </div>
        )} */}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2" htmlFor="gender">
          Gender
        </label>
        <select
          name="gender"
          value={formData.gender}
          onChange={handleChange}
          required
          className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2" htmlFor="phone">
          Phone
        </label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
           className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
        Preferred email address for employer inquiries
         </label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
           className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 font-bold mb-2  flex items-center"
          htmlFor="location"
        >
          Location
          <Tooltip
            title="If the location is not in the list, please fill out the feedback form, and we will add the location within a few hours."
            position="right"
            trigger="mouseenter"
            arrow={true}
            theme="light"
            className="ml-2"
          >
            <FiInfo
              className="text-gray-500 hover:text-gray-700 cursor-pointer"
              onClick={handleInfoClick}
            />
          </Tooltip>
        </label>
        <input
          type="text"
          name="location"
          placeholder="Start typing..."
          value={formData.location}
          onChange={handleChange}
          required
           className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        {showDropdown && (
          <div className="relative">
            <div className="absolute z-10 bg-white border border-gray-300 rounded shadow-lg w-full mt-1 max-h-60 overflow-auto">
              {loading ? (
                <div className="p-2 text-center">
                  <BounceLoader color="#000" />
                </div>
              ) : suburbs.length > 0 ? (
                suburbs.map((suburb) => (
                  <div
                    key={suburb.Suburb}
                    className="p-2 cursor-pointer hover:bg-gray-200 text-black"
                    onClick={() => handleSelect(suburb)}
                  >
                    {suburb}
                  </div>
                ))
              ) : (
                <div className=" "> </div>
              )}
            </div>
          </div>
        )}
      </div>
      <fieldset className="border p-2 rounded">
        <legend className="text-lg font-semibold mb-2">Availability</legend>
        <div className="grid grid-cols-2 gap-4">
          {Object.keys(formData.availability).map((day) => (
            <div key={day} className="flex items-center">
              <input
                type="checkbox"
                name={day}
                checked={formData.availability[day]}
                onChange={handleChange}
                className="mr-2"
              />
              <label className="text-gray-700">{day}</label>
            </div>
          ))}
        </div>
      </fieldset>
      <div className="mt-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            name="policeCheck"
            checked={formData.policeCheck}
            onChange={handleChange}
           className="mr-2 transform scale-150 bg-white border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <label htmlFor="policeCheck" className="text-gray-700">
            <strong> Currently hold police check</strong>
          </label>
        </div>
      </div>
      <div className="mt-4">
        <label
          className="block text-gray-700 font-bold mb-2"
          htmlFor="yearsExperience"
        >
          Years of Experience
        </label>
        <input
          type="number"
          min={0}
          name="yearsExperience"
          value={formData.yearsExperience}
          onChange={handleChange}
          required
          className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div className="mt-4">
        <fieldset className="border p-2 rounded">
          <legend className="text-lg font-semibold mb-2">Certificates</legend>
          {certificates.map((certificate, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={certificate}
                onChange={(e) => handleCertificateChange(index, e)}
                placeholder={`Certificate ${index + 1}`}
                className="w-full p-2 border rounded mr-2"
              />
              <button
                type="button"
                onClick={() => removeCertificateField(index)}
                className="bg-red-500 text-white px-2 py-1 rounded"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={addCertificateField}
            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
          >
            Add Certificate
          </button>
        </fieldset>
      </div>
      <div className="mt-4">
        <fieldset className="border p-2 rounded">
          <legend className="text-lg font-semibold mb-2">Any Notes</legend>
          <textarea
            name="description"
            placeholder="Write something about yourself i.e your experience, your skills, etc."
            type="text"
            maxLength={300}
            value={formData.description}
            onChange={handleChange}
            className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </fieldset>
      </div>

      <div className="mt-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            name="sharePhone"
            checked={formData.sharePhone}
            onChange={handleChange}
            className="mr-2 transform scale-150 bg-white border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <label htmlFor="sharePhone" className="text-gray-700">
            Share my phone number with employers
          </label>
        </div>
        <div className="flex items-center mt-2">
          <input
            type="checkbox"
            name="termsAccepted"
            checked={formData.termsAccepted}
            onChange={handleChange}
           className="mr-2 transform scale-150 bg-white border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <label htmlFor="termsAccepted" className="text-gray-700">
            I accept the
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:underline ml-1"
            >
              Terms and Conditions
            </a>
            .
          </label>
        </div>
      </div>

      <button
        type="submit"
        className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
      >
        Submit
      </button>
    </form>
  );
};

export default FormForWorker;
