import React, { useContext, useState, useEffect } from "react";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaUserFriends,
  FaCheckCircle,
  FaFileDownload,
} from "react-icons/fa"; // Import icons for phone, email, and location
import AuthContext from "../AuthContext";
import Modal from "react-modal"; // Import react-modal for dialogs
import { useNavigate } from "react-router-dom"; // For navigation
import Pricing from "./Pricing";
import boyPic from "../images/boy.png";
import girlPic from "../images/girl.webp";
import defaultPic from "../images/defaultpic.png";

const WorkerCard = ({
  name,
  job,
  phone,
  email,
  location,
  description,
  certificates,
  gender,
  policeCheck,
  yearsExperience,
  availability,
  sharePhone,
  profileImage,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubscriptionDialogOpen, setShowSubscriptionRequiredDialog] =
    useState(false);
  const [modalContent, setModalContent] = useState("");
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const { isLoggedOut, userRole, isSubscriptionRequired } =
    useContext(AuthContext);
  const navigate = useNavigate(); // Hook to navigate to different pages

  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);

  // Retrieve user email from local storage
  const user = JSON.parse(localStorage.getItem("user"));
  const userEmail = user ? user.email : null;

  const handleCopyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text);
    alert(`${type} has been copied to clipboard!`);
  };

  const showActionDialog = () => {
    if (isLoggedOut) {
      ShowSignInOrRegisterDialog();
      return;
    }

    if (userRole === "worker") {
      ShowSwitchYourProfileTobeEmployer();
      return;
    }

    if (isSubscriptionRequired) {
      ShowSubscriptionRequiredDialog();
    }
  };

  const ShowSubscriptionRequiredDialog = () => {
    setShowSubscriptionRequiredDialog(true);
  };

  const closeSubscriptionDialog = () => {
    setShowSubscriptionRequiredDialog(false);
  };

  // Method to show sign-in or register dialog
  const ShowSignInOrRegisterDialog = () => {
    setModalContent("Please sign in or register to see the contact details.");
    setIsModalOpen(true);
  };

  // Method to show switch profile dialog
  const ShowSwitchYourProfileTobeEmployer = () => {
    setModalContent("Only employers can  access details.");
    setIsModalOpen(true);
  };

  // Method to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSubscriptionEvent = (eventType) => {
    if (eventType === "subscribe") {
      closeSubscriptionDialog();
    } else if (eventType === "later") {
      closeSubscriptionDialog();
    }
  };

  // Method to navigate based on modal content
  const handleNavigation = () => {
    closeModal();
    if (modalContent.includes("sign in or register")) {
      navigate("/signin"); // Navigate to sign in page
    } else if (modalContent.includes("switch your profile")) {
      navigate("/profile/switch"); // Navigate to profile switch page
    }
  };

  const getProfilePic = (gender) => { 
    if (gender === "Male") {
      return boyPic; // Replace with actual path to boyPic
    } else if (gender === "Female") {
      return girlPic; // Replace with actual path to girlPic
    } else {
      return defaultPic; // Replace with actual path to defaultPic
    }
  };

  return (
    <div className="max-w-sm   rounded overflow-hidden shadow-lg bg-white m-4 border border-gray-300 group-hover:scale-105 transform transition-transform duration-300 ">
      <div className="px-6 py-4  ">
        <div className="flex items-center mb-4">
          {profileImage ? (
            <img
              src={profileImage} // Use provided profilePic or default
              alt={`${name}'s profile`}
              className="w-16 h-16 object-cover  rounded-full mr-4"
            />
          ) : (
            <img
              src={getProfilePic(gender)} // Use provided profilePic or default
              alt={`${name}'s profile`}
              className="w-16 h-16 rounded-full mr-4"
            />
          )}

          <div>
            <div className="font-bold text-xl">{name}</div>
            <div className="text-gray-600">{job}</div>
          </div>
        </div>

        <div className="flex items-center mb-4">
          <FaMapMarkerAlt
            size={15}
            className="text-blue-600 mr-2"
            title={location}
          />
          <span className="text-gray-700 text-sm ">{location}</span>
        </div>
        {/* <p className="text-gray-900 text-base mb-2">{name} is looking for a job as {job}</p> */}

        <div className="flex items-center mb-2">
          {sharePhone &&
            phone &&
            !(
              isLoggedOut ||
              userRole === "worker" ||
              isSubscriptionRequired
            ) && (
              <div
                className="flex items-center mr-2 cursor-pointer"
                onClick={() => handleCopyToClipboard(phone, "Phone number")}
              >
                <FaPhoneAlt size={20} className="text-red-600 " />
                <span className="text-gray-700 text-base  ml-2">
                  <strong>{phone}</strong>
                </span>
              </div>
            )}

          {(isLoggedOut || userRole === "worker" || isSubscriptionRequired) && (
            <div
              className="flex items-center mr-2 cursor-pointer"
              onClick={() => showActionDialog()}
            >
              <FaPhoneAlt size={20} className="text-red-600 " />
              <span
                className="text-gray-700 text-base  ml-2"
                style={{ filter: "blur(2.5px)" }}
              >
                <strong>0701824243</strong>
              </span>
            </div>
          )}
        </div>

        <div className="flex items-center mb-2">
          {email &&
            !(
              isLoggedOut ||
              userRole === "worker" ||
              isSubscriptionRequired
            ) && (
              <div
                className="mr-2 flex items-center cursor-pointer"
                onClick={() => handleCopyToClipboard(email, "Email address")}
              >
                <FaEnvelope size={20} className="text-yellow-600 " />
                <span className="text-gray-700 text-base ml-2">
                  <strong>{email}</strong>
                </span>
              </div>
            )}

          {(isLoggedOut || userRole === "worker" || isSubscriptionRequired) && (
            <div
              className="mr-2 flex items-center cursor-pointer"
              onClick={() => showActionDialog()}
            >
              <FaEnvelope size={20} className="text-yellow-600 " />
              <span
                className="text-gray-700 text-base ml-2"
                style={{ filter: "blur(2.5px)" }}
              >
                <strong>sample@asmail.com</strong>
              </span>
            </div>
          )}
        </div>

        <div className="mt-4">
          <h3 className="font-semibold text-lg mb-2">Available</h3>
          <div className="grid grid-cols-3 gap-2">
            {days.map((day, index) => (
              <Tooltip
                key={index}
                title={
                  availability[day]
                    ? `${name} is available on ${day}`
                    : `${name} not available on ${day}`
                }
                position="top"
                trigger="mouseenter"
                arrow={true}
              >
                <span
                  className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 mb-2 ${
                    availability[day]
                      ? "bg-green-200 text-green-700"
                      : "bg-red-200 text-red-700"
                  }`}
                >
                  {day}
                </span>
              </Tooltip>
            ))}
          </div>
        </div>

        {certificates.filter((certificate) => /[a-zA-Z]/.test(certificate))
          .length > 0 && (
          <div className="mt-4">
            <p className="text-gray-700">
              <strong>Certificates:</strong>
            </p>
            {certificates.filter((certificate) => /[a-zA-Z]/.test(certificate))
              .length > 0 ? (
              certificates
                .filter((certificate) => /[a-zA-Z]/.test(certificate))
                .map((certificate, index) => (
                  <span
                    key={index}
                    className="inline-block bg-blue-200 rounded-full px-3 py-1 text-sm font-semibold text-blue-700 mr-2 mb-2"
                  >
                    {certificate}
                  </span>
                ))
            ) : (
              <p className="text-gray-700 text-base w-96">
                No certificates added.
              </p>
            )}
          </div>
        )}

        <div className="mt-4">
          <fieldset className="border border-gray-300 p-2 rounded mb-4">
            <legend className="text-sm text-gray-700">
              <strong>Notes</strong>
            </legend>
            <p className="text-gray-700 text-base">
              {" "}
              {description
                ? description
                : name + " is looking for a job as " + job}
            </p>
          </fieldset>
        </div>

        <div className="mt-4">
          <fieldset className="border border-gray-300 p-2 rounded mb-4">
            <legend className="  text-gray-700">
              {" "}
              <strong>Additional Information</strong>
            </legend>
            {/* <strong className="text-gray-700 text-sm">
              Proof of police check:{" "}
            </strong>
            {policeCheck ? "Yes" : "No"} */}
            <div>
              <strong className="text-gray-700  text-sm">
                Years of experience:{" "}
              </strong>
              {yearsExperience
                ? yearsExperience + " years"
                : "Less than a year"}
            </div>
            <div>
              <strong className="text-gray-700  text-sm">Gender: </strong>
              {gender}
            </div>
          </fieldset>

          <div className="mt-4 flex justify-center">
            <Tooltip
              title="This feature is not available at the moment"
              position="top"
              trigger="mouseenter"
              arrow={true}
            >
              <button
                disabled
                className="bg-orange-500 opacity-50 text-sm cursor-not-allowed  text-white   py-2 px-4 rounded inline-flex items-center"
              >
                <FaFileDownload className="mr-2" />
                Download Resume
              </button>
            </Tooltip>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between mt-4">
          {sharePhone &&
            phone &&
            !(
              isLoggedOut ||
              userRole === "worker" ||
              isSubscriptionRequired
            ) && (
              <a href={`tel:${phone}`} title={`Call ${phone}`}>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Phone
                </button>
              </a>
            )}
          {(isLoggedOut || userRole === "worker" || isSubscriptionRequired) && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => showActionDialog()}
            >
              Phone
            </button>
          )}

          {email &&
            !(
              isLoggedOut ||
              userRole === "worker" ||
              isSubscriptionRequired
            ) && (
              <a href={`mailto:${email}`} title={`Email ${email}`}>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Email
                </button>
              </a>
            )}

          {(isLoggedOut || userRole === "worker" || isSubscriptionRequired) && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => showActionDialog()}
            >
              Email
            </button>
          )}
        </div>
      </div>
      {/* Modal for Sign In/Registration or Profile Switch */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="User Action Dialog"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center border-green-500">
          <button
            onClick={closeModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div className="text-yellow-500 text-4xl mb-4">
            <FaUserFriends />
          </div>
          <h2>{modalContent}</h2>
          <div className="flex">
            {modalContent.includes("sign in or register") && (
              <button
                onClick={() => {
                  closeModal();
                  navigate("/login");
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
              >
                Sign In
              </button>
            )}
            {modalContent.includes("sign in or register") && (
              <button
                onClick={() => {
                  closeModal();
                  navigate("/register");
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
              >
                Register
              </button>
            )}

            <button
              onClick={closeModal}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isSubscriptionDialogOpen}
        onRequestClose={closeSubscriptionDialog}
        contentLabel="Subscription Dialog"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center border-green-500border`}
        >
          <button
            onClick={closeSubscriptionDialog}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div className={`text-4xl mb-4  text-green-500`}>
            <FaCheckCircle />
          </div>
          <h3 className="font-bold mb-4">
            Subscription required to view contact details
          </h3>
          <div className="flex gap-4">
            <Pricing
              email={userEmail}
              laterButton={true}
              onEvent={handleSubscriptionEvent}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WorkerCard;
