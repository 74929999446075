import React from 'react';

const OffersPending = () => {
    return (
        <div>
            <h1>Offers Pending</h1>
            <p>You have pending offers. Please review them below:</p>
            {/* Add your offer pending list or components here */}
        </div>
    );
};

export default OffersPending;