import { useState } from 'react';

const OfferMessage = ({ message }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(!isExpanded);

  // Set a character limit for the preview
  const previewLimit = 100;
  const isLongMessage = message.length > previewLimit;

  return (
    <div className="text-sm" style={{ whiteSpace: 'pre-wrap' }}>
      {isExpanded || !isLongMessage ? (
        message // Show full message if expanded or if the message is short
      ) : (
        // Show a preview of the message with the character limit
        `${message.slice(0, previewLimit)}...`
      )}

      {isLongMessage && (
        <button
          onClick={toggleExpanded}
          className="text-blue-500 ml-2 focus:outline-none"
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </button>
      )}
    </div>
  );
}

export default OfferMessage;