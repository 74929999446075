import React from "react";
import { useState, useContext, useEffect } from "react";
import AuthContext from "../../AuthContext";
import axiosInstance from "../../axiosInstance";
import { FaSpinner } from "react-icons/fa"; // Import spinner icon
import TaskCard from "./TaskCard";
import TaskDetailsModalForOwner from "./TaskDetailsModalForOwner";

const PostedTasks = (showSignRegisterInModal) => {
  const [tasks, setTasks] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const { isLoggedOut } = useContext(AuthContext);

  useEffect(() => {
    axiosInstance
      .get("/api/tasks/getUsersTasks")
      .then((response) => {
        setTasks(response.data.tasks); 
        setLoading(false); // Stop loading when data is received
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
        setError("Failed to load tasks. Please try again later."); // Set error message
        setLoading(false); // Stop loading even if there's an error
      });
  }, []);

  const openModal = (task) => {
    //check if user is logged in or not other
    if (isLoggedOut) {
      showSignRegisterInModal();
      return;
    }

    setSelectedTaskId(task._id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedTaskId(null);
    setModalIsOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center ">
      {loading ? (
        <div className="flex items-center justify-center  ">
          <FaSpinner className="text-blue-500 animate-spin text-4xl" />
          <span className="text-blue-500 text-lg ml-2">Loading tasks...</span>
        </div>
      ) : error ? (
        <div className="text-red-600 text-center mt-4">{error}</div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl mt-1">
          {tasks.map((task) => (
            <TaskCard key={task.id} task={task} onOpenModal={openModal} />
          ))}
        </div>
      )}
      {!loading && tasks.length === 0 && (
        <div className="text-center mt-4">
          You have not posted any tasks yet. Click the button above to post a new task.
        </div>
      )}

      <TaskDetailsModalForOwner
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        taskId={selectedTaskId}
      />
    </div>
  );
};

export default PostedTasks;
