import React, { useState, useEffect, useContext } from "react";
import axiosInstance from "../axiosInstance";
import AuthContext from "../AuthContext";
import Modal from "react-modal";
import FormForAdviser from "../components/FormForAdviser";
import { FaTimes, FaTimesCircle, FaCheckCircle, FaSmile } from "react-icons/fa";
import Pricing from "../components/Pricing";
import FormForWorker from "../components/FormForWorker";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const { loggedUser, isSubscriptionRequired, updateProfilePic } =
    useContext(AuthContext);
  const [AdviserModalIsOpen, setAdviserModalIsOpen] = useState(false);
  const [WorkerModalIsOpen, setWorkerModalIsOpen] = useState(false);
  const [workerFormDataToEdit, setWorkerFormDataToEdit] = useState(null);
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] =
    useState(false);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("success");
  const [adviserFormDataToEdit, setadviserFormDataToEdit] = useState(null);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
    role: "",
  });

  const [formData, setFormData] = useState({
    profileImage: null,
  });
  const [profilePreview, setProfilePreview] = useState(null);

  const [postToDelete, setPostToDelete] = useState(null);

  const [advisersPost, setAdvisersPost] = useState([]);
  const [workerPosts, setWorkerPosts] = useState([]);
  const [profileUploadedCompleted, setProfileUploadedCompleted] =
    useState(false);
    const [profileImageUpdating, setProfileImageUpdating] = useState(false);
  const navigate = useNavigate();

  // Fetch user details from localStorage
  useEffect(() => {
    const localUserData = JSON.parse(localStorage.getItem("user"));
    if (localUserData) {
      setUserData(localUserData);
    }
  }, []);

  useEffect(() => {
    fetchJobPostsDataByEmail();
  }, []);

  useEffect(() => {
    fetchLookingForWorkByEmail();
  }, []);

  const fetchJobPostsDataByEmail = async () => {
    const advisersResponse = await axiosInstance.get(
      `/api/findwork/advisersposts`,
      {
        params: {
          email: loggedUser.email,
        },
      }
    );
    setAdvisersPost(advisersResponse.data);
  };

  const fetchLookingForWorkByEmail = async () => {
    const lookingForWorkResponse = await axiosInstance.get(
      `/api/findworker/workerposts`,
      {
        params: {
          email: loggedUser.email,
        },
      }
    );
    setWorkerPosts(lookingForWorkResponse.data);
  };

  const handleWorkerDeletePost = async (postId) => { 
    try {
      await axiosInstance.delete(`/api/findwork/delete`, {
        data: {
          _id: postId,
        },
      });
      setWorkerPosts((prevPosts) =>
        prevPosts.filter((post) => post._id !== postId)
      );
      setMessage("Worker data deleted successfully");
      setMessageType("success");
    } catch (error) {
      setMessage("Error deleting worker data");
      setMessageType("error");
    } finally {
      setShowMessageModal(true);
    }
  };

  const handleWorkerEditPost = (post) => {
    setWorkerFormDataToEdit(post);
    setWorkerModalIsOpen(true);
  };

  // const handleWorkerEditPost = (post) => {

  const handleAdvisorFormSubmit = async (data) => {
    try {
      await axiosInstance.put(`/api/findworker/update`, {
        _id: data._id,
        ...data,
      });
      setMessage("Data updated successfully");
      setMessageType("success");
    } catch (error) {
      setMessage("Error updated the data");
      setMessageType("error");
    } finally {
      closeAdviserModal();
      setShowMessageModal(true);
    }
  };

  const openAdviserModal = () => {
    setAdviserModalIsOpen(true);
  };

  const closeAdviserModal = () => {
    setAdviserModalIsOpen(false);
  };

  const closeMessageModal = () => {
    setShowMessageModal(false);
    // Fetch the updated data
    fetchJobPostsDataByEmail();
  };

  const handleAdviserDeletePost = (postId) => {
    setPostToDelete(postId);
    setConfirmDeleteModalIsOpen(true); // Open confirmation modal
  };

  const closeConfirmDeleteModal = () => {
    setConfirmDeleteModalIsOpen(false);
  };

  const handleAdviserConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`/api/findworker/delete`, {
        data: {
          _id: postToDelete,
        },
      });
      setMessage("Adviser data deleted successfully");
      setMessageType("success");
      // Filter out the deleted post
      setAdvisersPost((prevPosts) =>
        prevPosts.filter((post) => post.id !== postToDelete)
      );
    } catch (error) {
      setMessage("Error deleting adviser data");
      setMessageType("error");
    } finally {
      setConfirmDeleteModalIsOpen(false); // Close confirmation modal
      setShowMessageModal(true);
    }
  };

  const handleAdviserEditPost = (post) => {
    if (isSubscriptionRequired) {
      ShowSubscriptionDialogbox();
      return;
    }
    setadviserFormDataToEdit(post);
    openAdviserModal();
  };

  const ShowSubscriptionDialogbox = () => {
    setIsSubscriptionDialogOpen(true);
  };

  const closeSubscriptionDialog = () => {
    setIsSubscriptionDialogOpen(false);
  };

  const handleSubscriptionEvent = (eventType) => {
    if (eventType === "subscribe") {
      closeSubscriptionDialog();
    }
    if (eventType === "later") {
      closeSubscriptionDialog();
      // Handle later logic here
    }
  };
  const closeWorkerModal = () => {
    setWorkerModalIsOpen(false);
  };

  const handleWorkerFormSubmit = async (data) => {
    try {
      await axiosInstance.put(`/api/findwork/update`, data);
      setMessage("Data updated successfully");
      setMessageType("success");
      fetchLookingForWorkByEmail();
    } catch (error) {
      setMessage("Error creating worker data");
      setMessageType("error");
    } finally {
      closeWorkerModal();
      setShowMessageModal(true);
    }
  };

  const handleFileChange = (e) => {
    setProfileUploadedCompleted(false); // Reset profile upload status
    setFormData({
      ...formData,
      profileImage: e.target.files[0], // Access the first file in the files array
    });

    setProfilePreview(URL.createObjectURL(e.target.files[0])); // Set preview
  };

  const updateImage = async () => {
    try {
      setProfileImageUpdating(true);
      const formDataToSend = new FormData();
      formDataToSend.append("profileImage", formData.profileImage);
      const response = await axiosInstance.post(
        "/api/register/updateProfilePicture",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      updateProfilePic(response.data.profileImageUrl);

      setMessage("Profile image uploaded successfully");

      setProfileUploadedCompleted(true);
    } catch (error) {
      console.error("Error uploading image:", error);
      setMessage("Error uploading profile image");
      setMessageType("error");
    } finally {
      setProfileImageUpdating(false);
      setShowMessageModal(true);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Your Details</h1>

      {/* Profile Section */}
      <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-8 rounded-lg shadow-lg mb-8 border border-indigo-200">
        <h2 className="text-3xl font-extrabold text-gray-800 mb-6">Profile</h2>

        <div className="ml-4 space-y-2 text-lg text-gray-700">
          <p>
            <span className="font-semibold text-indigo-600">Name:</span>
            <span className="ml-2">{userData.name}</span>
          </p>
          <p>
            <span className="font-semibold text-indigo-600">Email:</span>
            <span className="ml-2">{userData.email}</span>
          </p>
          <p>
            <span className="font-semibold text-indigo-600">Phone:</span>
            <span className="ml-2">{userData.phone}</span>
          </p>
          <p>
            <span className="font-semibold text-indigo-600">Location:</span>
            <span className="ml-2">{userData.location}</span>
          </p>
          <p>
            <span className="font-semibold text-indigo-600">Role:</span>
            <span className="ml-2">
              {" "}
              {userData.role.charAt(0).toUpperCase() + userData.role.slice(1)}
            </span>
          </p>

          <div className="mt-4">
            <button
              onClick={() => navigate("/change-password")}
              className="text-white px-3 py-2 bg-blue-500 rounded-lg hover:text-blue-700"
            >
              Change password
            </button>
          </div>

          {isSubscriptionRequired && (
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-5 rounded mt-4"
              onClick={ShowSubscriptionDialogbox}
            >
              Subscribe
            </button>
          )}
          <div className="mb-6">
            <label
              htmlFor="profileImage"
              className="w-56 flex flex-col items-center justify-center px-6 py-8 bg-white text-blue-600 rounded-lg border-2 border-gray-300 cursor-pointer hover:bg-blue-50 transition-all duration-200 shadow-md hover:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 text-blue-500 mb-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
              <span className="text-sm font-medium">Upload Profile Image</span>
              <input
                type="file"
                name="profileImage"
                id="profileImage"
                accept="image/*"
                onChange={handleFileChange}
                className="hidden"
              />
            </label>

            {/* Profile Preview Section */}
            {profilePreview && !profileUploadedCompleted && (
              <div className="mt-4 flex items-center space-x-4">
                <img
                  src={profilePreview}
                  alt="Profile Preview"
                  className="w-24 h-24 rounded-full object-cover shadow-md border-2 border-gray-200"
                />
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md mt-4 transition-all duration-150 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onClick={updateImage}
                  disabled={profileImageUpdating}
                >
                {profileImageUpdating ? "Uploading..." : "Update Image"}
                </button>
              </div>
            )}

            {/* Success message after upload */}
            {profileUploadedCompleted && (
              <div className="mt-4 text-green-500 text-sm font-semibold">
                Profile image uploaded successfully!
              </div>
            )}
          </div>

          {/* {["both", "employer"].includes(userData.role) && (
            <p>
              <span className="font-semibold text-indigo-600">
                Membership Expires:
              </span>
              <span className="ml-2">
                {new Date(userData.membershipExpires).toLocaleDateString(
                  "en-GB"
                )}
              </span>
            </p>
          )} */}
        </div>
      </div>
      {advisersPost.length > 0 && (
        <div className="bg-white p-6 rounded-lg shadow-md mb-8">
          <h2 className="text-2xl font-bold mb-6"> Posts Jobs</h2>
          {advisersPost.map((post) => (
            <div
              key={post.id}
              className="mb-6 p-4 border rounded-lg shadow-sm bg-gray-50"
            >
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                {post.job}
              </h3>
              <p className="text-gray-600 mb-4">
                <span className="font-bold">Posted on:</span>{" "}
                {new Date(post.postCreatedAt).toLocaleDateString("en-GB")}
              </p>
              <div className="flex flex-wrap space-x-2 mt-1">
                {Object.entries(post.availability).map(([day, available]) => (
                  <div className="mt-1">
                    <span
                      key={day}
                      className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${
                        available
                          ? "bg-green-200 text-green-800"
                          : "bg-red-200 text-red-800"
                      }`}
                    >
                      {day}
                    </span>
                  </div>
                ))}
              </div>
              <div className="flex space-x-4 mt-3">
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-5 rounded"
                  onClick={() => handleAdviserEditPost(post)}
                >
                  Edit Post
                </button>
                <button
                  className="bg-red-600 hover:bg-red-700 text-white py-2 px-5 rounded"
                  onClick={() => handleAdviserDeletePost(post._id)}
                >
                  Delete Post
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {workerPosts.length > 0 && (
        <div className="bg-white p-6 rounded-lg shadow-md mb-8">
          <h2 className="text-2xl font-bold mb-6"> Looking For Work Posts</h2>
          {workerPosts.map((post) => (
            <div
              key={post.id}
              className="mb-6 p-4 border rounded-lg shadow-sm bg-gray-50"
            >
              <h3 className="text-xl font-semibold mb-2 text-gray-800">
                {post.job}
              </h3>
              <p className="text-gray-600 mb-4">
                <span className="font-bold">Posted on:</span>{" "}
                {new Date(post.postCreatedAt).toLocaleDateString("en-GB")}
              </p>
              <div className="flex flex-wrap space-x-2 mt-1">
                {Object.entries(post.availability).map(([day, available]) => (
                  <div className="mt-1">
                    <span
                      key={day}
                      className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${
                        available
                          ? "bg-green-200 text-green-800"
                          : "bg-red-200 text-red-800"
                      }`}
                    >
                      {day}
                    </span>
                  </div>
                ))}
              </div>
              <div className="flex space-x-4 mt-3">
                <button
                  className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-5 rounded"
                  onClick={() => handleWorkerEditPost(post)}
                >
                  Edit Post
                </button>
                <button
                  className="bg-red-600 hover:bg-red-700 text-white py-2 px-5 rounded"
                  onClick={() => handleWorkerDeletePost(post._id)}
                >
                  Delete Post
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal
        isOpen={AdviserModalIsOpen}
        onRequestClose={closeAdviserModal}
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg mx-auto max-h-[90vh] overflow-y-auto">
          <button
            onClick={closeAdviserModal}
            className="float-right text-gray-500 hover:text-gray-700"
          >
            <FaTimes size={20} />
          </button>
          <FormForAdviser
            existingData={adviserFormDataToEdit}
            onSubmit={handleAdvisorFormSubmit}
          />
        </div>
      </Modal>

      <Modal
        isOpen={WorkerModalIsOpen}
        onRequestClose={closeWorkerModal}
        contentLabel="Worker Form"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg mx-auto max-h-[90vh] overflow-y-auto">
          <button
            onClick={closeWorkerModal}
            className="float-right text-gray-500 hover:text-gray-700"
          >
            <FaTimes size={20} />
          </button>
          <FormForWorker
            existingData={workerFormDataToEdit}
            onSubmit={handleWorkerFormSubmit}
          />
        </div>
      </Modal>

      {/* Message Modal */}
      <Modal
        isOpen={showMessageModal}
        onRequestClose={closeMessageModal}
        contentLabel="Submission Status"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center ${
            messageType === "success" ? "border-green-500" : "border-red-500"
          } border`}
        >
          <button
            onClick={closeMessageModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <FaTimes size={20} />
          </button>
          {messageType === "success" ? (
            <FaCheckCircle size={48} className="text-green-500 mb-4" />
          ) : (
            <FaTimesCircle size={48} className="text-red-500 mb-4" />
          )}
          <h2 className="text-lg font-bold mb-2">
            {messageType === "success" ? "Success" : "Error"}
          </h2>
          <p className="text-center">{message}</p>
          <button
            onClick={closeMessageModal}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Close
          </button>
        </div>
      </Modal>
      {/* Message Modal */}
      <Modal
        isOpen={showMessageModal}
        onRequestClose={closeMessageModal}
        contentLabel="Submission Status"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center ${
            messageType === "success" ? "border-green-500" : "border-red-500"
          } border`}
        >
          <button
            onClick={closeMessageModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            <FaTimes size={20} />
          </button>
          {messageType === "success" ? (
            <FaCheckCircle size={48} className="text-green-500 mb-4" />
          ) : (
            <FaTimesCircle size={48} className="text-red-500 mb-4" />
          )}
          <h2 className="text-lg font-semibold text-center mb-2">{message}</h2>
          <button
            onClick={closeMessageModal}
            className={`mt-4 px-4 py-2 text-white ${
              messageType === "success" ? "bg-green-500" : "bg-red-500"
            } rounded hover:opacity-90`}
          >
            OK
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={confirmDeleteModalIsOpen}
        onRequestClose={closeConfirmDeleteModal}
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto">
          <h2 className="text-lg font-bold mb-4">Confirm Deletion</h2>
          <p>Are you sure you want to delete this post?</p>
          <div className="flex justify-end mt-6">
            <button
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded mr-2"
              onClick={closeConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded"
              onClick={handleAdviserConfirmDelete}
            >
              Confirm Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isSubscriptionDialogOpen}
        onRequestClose={closeSubscriptionDialog}
        contentLabel="Subscription Dialog"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center border-green-500border`}
        >
          <button
            onClick={closeSubscriptionDialog}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div className={`text-4xl mb-4  text-green-500`}>
            <FaSmile />
          </div>
          <h3 className="font-bold mb-4">Subscription Now</h3>
          <div className="flex gap-4">
            <Pricing
              email={userData.email}
              laterButton={true}
              onEvent={handleSubscriptionEvent}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
