import React, { createContext, useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [isSubscriptionRequired, setisSubscriptionRequired] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    if (token && user) {
      verifyToken(token);
    } else {
      setIsLoggedIn(false);
      setIsLoggedOut(true);
      setUserRole(null);
    }
  }, []);

  const verifyToken = async (token) => {
    try {
      const response = await axiosInstance.post("/api/login/verifyToken", {
        token,
      });
      if (response.status === 200) {
        const user = JSON.parse(localStorage.getItem("user"));

        setIsLoggedIn(true);
        setIsLoggedOut(false);
        setUserRole(user.role);
        if (user.profileImage) {
          setProfileImageUrl(user.profileImage);
        }
        checkIfSubscriptionIsRequiredFromLocalStorage(user);
      } else {
        logout();
      }
    } catch (error) {
      console.error("Token verification failed:", error);
      logout();
    }
  };

  const login = (UserObj) => {
    localStorage.setItem("token", UserObj.token);
    localStorage.setItem("user", JSON.stringify(UserObj.user)); 
    if (UserObj.user.profileImage) { 

      setProfileImageUrl(UserObj.user.profileImage);
    }
    setIsLoggedIn(true);
    checkIfSubscriptionIsRequired(UserObj);
    setUserRole(UserObj.user.role); // Set user rol
    setIsLoggedOut(false);
  };

  const checkIfSubscriptionIsRequired = (UserObj) => {
    if (UserObj.user.role === "employer" || UserObj.user.role === "both") {
      const membershipExpires = UserObj.user.membershipExpires
        ? new Date(UserObj.user.membershipExpires)
        : null;
      const currentDate = new Date();

      if (membershipExpires === null || membershipExpires < currentDate) {
        setisSubscriptionRequired(true);
      } else {
        setisSubscriptionRequired(false);
      }
    }
  };

  const checkIfSubscriptionIsRequiredFromLocalStorage = (user) => {
    if (user.role === "employer" || user.role === "both") {
      const membershipExpires = user.membershipExpires
        ? new Date(user.membershipExpires)
        : null;
      const currentDate = new Date();

      if (membershipExpires === null || membershipExpires < currentDate) {
        setisSubscriptionRequired(true);
      } else {
        setisSubscriptionRequired(false);
      }
    }
  };

  const updateProfilePic = (profileImageUrl) => {
    const user = JSON.parse(localStorage.getItem("user"));
    user.profileImage = profileImageUrl;
    localStorage.setItem("user", JSON.stringify(user));
    setProfileImageUrl(profileImageUrl);
  };

  const loggedUser = JSON.parse(localStorage.getItem("user"));

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setProfileImageUrl(null);
    setIsLoggedIn(false);
    setUserRole(null);
    setIsLoggedOut(true);
    setisSubscriptionRequired(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLoggedOut,
        userRole,
        login,
        logout,
        isSubscriptionRequired,
        loggedUser,
        updateProfilePic,
        profileImageUrl
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
