import React, { useState, useContext } from "react";
import axiosInstance from "../axiosInstance";
import { useNavigate } from "react-router-dom";
import AuthContext from "../AuthContext";
import Modal from "react-modal"; // Import Modal for the forgot password
import { FaCheckCircle } from "react-icons/fa"; // Icons for success and error
import Pricing from "../components/Pricing";

// Optional: Style for the modal
Modal.setAppElement("#root");

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { login } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotEmailMessage, setForgotEmailMessage] = useState(""); // Renamed from successMessage
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/api/login", formData); 
      login(response.data.UserObj);
      if (
        response.data.UserObj.user.role === "employer" ||
        response.data.UserObj.user.role === "both"
      ) { 
        const membershipExpires = response.data.UserObj.user.membershipExpires
          ? new Date(response.data.UserObj.user.membershipExpires)
          : null;
        const currentDate = new Date();

        if (membershipExpires === null || membershipExpires < currentDate) { 
          ShowSubscriptionDialogbox();
        }else{
          navigate("/");
        }
        // Show subscription dialog box
      } else {
        navigate("/"); // Redirect to home page
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage("Invalid email or password.");
      } else {
        setErrorMessage("An error occurred during login. Please try again.");
      }
    }
  };

  // Forgot password handler
  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post("/api/forgotPassword", { email: forgotEmail });
      setForgotEmailMessage(
        "Reset password instructions will be sent to your email shortly."
      );
      setForgotEmail(""); // Clear email input after submission
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setForgotEmailMessage("User not found.");
      } else {
        setForgotEmailMessage("An error occurred. Please try again.");
      }
    }
  };

  // Function to close the modal and reset the form
  const closeModal = () => {
    setIsModalOpen(false);
    setForgotEmail("");
    setForgotEmailMessage("");
  };

  const ShowSubscriptionDialogbox = () => {
    setIsSubscriptionDialogOpen(true);
  };

  const closeSubscriptionDialog = () => {
    setIsSubscriptionDialogOpen(false);
  };

  const handleSubscriptionEvent = (eventType) => {
    if(eventType === 'subscribe'){
      closeSubscriptionDialog();
    }
     if (eventType === 'later') {
      closeSubscriptionDialog();
      navigate("/")
      // Handle later logic here
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Login</h1>
      {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Your email"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
             className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Your password"
            required
          />
        </div>
        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Login
          </button>
        </div>
      </form>

      {/* Forgot Password Section */}
      <div className="mt-4">
        <button
          onClick={() => setIsModalOpen(true)} // Open the modal on click
          className="text-blue-500 hover:text-blue-700"
        >
          Forgot password?
        </button>
      </div> 
      <div className="mt-4">
        <button
          onClick={() => navigate("/change-password")}
          className="text-blue-500 hover:text-blue-700"
        >
          Change password
        </button>
      </div>

      {/* Register Link */}
      <div className="mt-4">
        <p className="text-gray-600">
          Don't have an account?{" "}
          <button
            onClick={() => navigate("/register")}
            className="text-blue-500 hover:text-blue-700"
          >
            Register here
          </button>
        </p>
      </div>

      {/* Forgot Password Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal} // Close the modal and reset the form
        contentLabel="Forgot Password Modal"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
          <h2 className="text-2xl mb-4 font-bold text-gray-700 ">
            Forgot Password
          </h2>
          {forgotEmailMessage ? (
            <div className="text-center">
              <p className="text-gray-600 mb-4">{forgotEmailMessage}</p>
              {forgotEmailMessage.includes("error") && (
                <button
                  onClick={() => navigate("/feedback")}
                  className="mt-2 mr-3 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Give Feedback
                </button>
              )}
              {forgotEmailMessage.includes("User not found") && (
                <button
                  onClick={() => navigate("/register")}
                  className="mt-2 mr-3 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Register
                </button>
              )}
              <button
                onClick={closeModal}
                className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Close
              </button>
            </div>
          ) : (
            <form onSubmit={handleForgotPasswordSubmit}>
              <p className="text-gray-600 mb-2">
                Enter your email address below and we'll send you instructions
                on how to reset your password.
              </p>
              <input
                type="email"
                value={forgotEmail}
                onChange={(e) => setForgotEmail(e.target.value)}
                placeholder="Enter your email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-4"
                required
              />
              <div className="flex justify-between">
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Send
                </button>
                <button
                  onClick={closeModal}
                  className="bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Cancel
                </button>
              </div>
            </form>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={isSubscriptionDialogOpen}
        onRequestClose={closeSubscriptionDialog}
        contentLabel="Subscription Dialog"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center border-green-500border`}
        >
          <button
            onClick={closeSubscriptionDialog}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div className={`text-4xl mb-4  text-green-500`}>
            <FaCheckCircle />
          </div>
          <h3 className="font-bold mb-4">
            Login successful. Subscription required
          </h3>
          <div className="flex gap-4">
            <Pricing email={formData.email} laterButton={true} onEvent={handleSubscriptionEvent}  />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Login;
