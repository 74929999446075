import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const Spinner = () => (
  <div className="flex items-center justify-center md:h-screen">
    <ThreeDots 
      height="80" 
      width="80" 
      radius="9" 
      color="#4a90e2" 
      ariaLabel="three-dots-loading" 
      visible={true} 
    />
  </div>
);

export default Spinner;
