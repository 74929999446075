//Make a small pricing component to show to the user which they are subscribing to.. make a card with $25/monthy and features like, Employers can post job listings. Find casual or temprory stuff. Can contact the staff members

import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import AuthContext from "../AuthContext";
import { FaSmileBeam } from "react-icons/fa";
import { useNavigate } from "react-router-dom";


Modal.setAppElement("#root");

const Pricing = ({ onEvent, email, laterButton }) => { 
  const [link, setLink] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();


  // Set StripeLink from environment variable and create link with prefilled email
  useEffect(() => {
    const stripeUrl = process.env.REACT_APP_STRIPE_PRODUCT_URL;

    if (email && stripeUrl) { 
      setLink(`${stripeUrl}?prefilled_email=${email.toLowerCase()}`);
    } else {
      setLink(stripeUrl);
    }
  }, [email]);

  const [isHovered, setIsHovered] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const logoutClicked = () => {
    onEvent("subscribe")
    closeModal();
    logout();
    navigate("/login");
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h2 style={styles.title} className="text-gray-600 font-bold">
          Skilled Mates Membership
        </h2>
        <div className="flex">
          {" "}
          <p style={styles.price} className="text-black">
            $10
          </p>
          <p
            style={styles.monthly}
            className="mt-4 ml-1 text-gray-500 font-semibold"
          >
            /MONTH
          </p>
        </div>

        <ul style={styles.features}>
          <li className="text-gray-600">
            {" "}
            &#10003; Employers can post job listings
          </li>
          <li>&#10003; Contact staff members directly</li>
          <li>&#10003; Find casual or temporary staff easily</li>
        </ul>
        <div className="flex justify-center">
          <a href={link} target="_blank" rel="noopener noreferrer">
            <button
              className="font-semibold w-100"
              style={{
                ...styles.button,
                ...(isHovered ? styles.buttonHover : {}),
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={openModal}
            >
              Subscribe
            </button>
          </a>
          {laterButton && (
            <button
              className="font-semibold w-100 ml-3 bg-orange-500"
              style={styles.button}
              onClick={() => onEvent("later")}
            >
              Later
            </button>
          )}
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Subscription Modal"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div
          className={`bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center border-green-500border`}
        >
          <button
            onClick={closeModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div className={`text-4xl mb-4  text-green-500`}>
            <FaSmileBeam />
          </div>
          <h3 className="font-bold mb-4">
          If you complete subscribing, please logout of the app and login again to reflect the changes.
          </h3>
          <div className="flex gap-4">
            <button onClick={logoutClicked}  className="font-semibold w-100 bg-orange-600"
              style={{
                ...styles.button,
                ...(isHovered ? styles.buttonHover : {}),
              }}>
              Logout
            </button>
            <button onClick={closeModal} style={styles.button}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
  },
  card: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "350px",
    textAlign: "center",
  },
  title: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  price: {
    fontSize: "30px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  features: {
    listStyleType: "none",
    padding: 0,
    textAlign: "left",
    marginBottom: "20px",
  },
  button: {
    backgroundColor: "#007bff",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "transform 0.3s ease", // Add transition for smooth scaling
  },
  buttonHover: {
    transform: "scale(1.1)", // Scale up the button on hover
  },
  monthly: {
    fontSize: "13px",
  },
};

export default Pricing;
