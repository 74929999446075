import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  FaSpinner,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaUser,
  FaUserCircle,
  FaDollarSign,
} from "react-icons/fa";
import axiosInstance from "../../axiosInstance";
import OfferMessage from "./OfferMessage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const TaskDetailsModal = ({ isOpen, onRequestClose, taskId }) => {
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("offers");
  const [newQuestion, setNewQuestion] = useState("");
  const [shownAnswers, setShownAnswers] = useState({});
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false); // State for the offer modal
  const [offerAmount, setOfferAmount] = useState(""); // State for the offer amount
  const platformFeePercentage = 0.1; // Assuming 10% platform fee
  const [underStantScopeOfWork, setUnderStantScopeOfWork] = useState(false);
  const [offerMessage, setOfferMessage] = useState("");
  const [offerSubmitting, setOfferSubmitting] = useState(false);
  const [
    checkingUsersMakeOfferRequirements,
    setCheckingUsersMakeOfferRequirements,
  ] = useState(false);
  const MySwal = withReactContent(Swal);

  //make sure offermessage check space as well
  const offerValidated =
    offerAmount && underStantScopeOfWork && offerMessage.trim().length > 0;

  useEffect(() => {
    const fetchTaskDetails = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/tasks/getTaskById/${taskId}`
        );
        setTask(response.data.task);
        setOfferAmount(response.data.task.originalBudget); // Set the default offer amount to the original budget
      } catch (error) {
        console.error("Error fetching task details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchTaskDetails();
    }
  }, [isOpen, taskId]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleQuestionSubmit = async () => {
    setNewQuestion(""); // Clear the input after submission
    try {
      const response = await axiosInstance.post(
        `/api/questions/askQuestion/${taskId}`,
        {
          question: newQuestion,
        }
      );
      setTask((prevState) => ({
        ...prevState,
        questions: [
          ...prevState.questions,
          {
            _id: response.data.question._id,
            question: newQuestion,
            askedBy: {
              name: "Me",
            },
          },
        ],
      }));
    } catch (error) {
      console.error("Error submitting question:", error);
    }
  };

  const toggleAnswerVisibility = (questionId) => {
    setShownAnswers((prevState) => ({
      ...prevState,
      [questionId]: !prevState[questionId],
    }));
  };

  // Function to handle the click on "Make Offer" button
  const handleMakeOfferClick = async () => {
    setCheckingUsersMakeOfferRequirements(true);
    const response = await CheckIfUserHasPayoutMethodAdded();
    if (response.data.taskPaymentMethodAdded) {
      setIsOfferModalOpen(true);
    }
    if (response.data.url && response.data.url !== "") {
      MySwal.fire({
        icon: "info",
        title: "Payout Details Required",
        text: "You need to add your payout details to proceed. A quick setup process will guide you through a few verification steps.",
        showCancelButton: true,
        confirmButtonText: "Add Payment",
        cancelButtonText: "Close",
      }).then((result) => {
        if (result.isConfirmed) {
          // If the user clicked "Add Payment", redirect them to the Stripe account link

          window.open(response.data.url, "_blank");
        }
      });
    }
    setCheckingUsersMakeOfferRequirements(false);
  };
  const CheckIfUserHasPayoutMethodAdded = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/tasks/checkIfUserHasTaskPaymentOutMethodAdded`
      );
      return response;
    } catch (error) {
      console.error("Unexpected error occurred:", error);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error checking your payment details. Please try again later.",
      });
      return null;
    }
  };

  // Calculate the total fee details
  const calculateFees = (amount) => {
    const fee = amount * platformFeePercentage; // Calculate the fee
    const totalAmount = amount - fee; // Amount that will come to the user
    return { fee, totalAmount };
  };

  const submitOffer = async () => {
    try {
      setOfferSubmitting(true);
      await axiosInstance.post(`/api/offers/submitOffer/${taskId}`, {
        amount: offerAmount,
        message: offerMessage,
      });
      setIsOfferModalOpen(false);
      //refresh the task details
      const response = await axiosInstance.get(
        `/api/tasks/getTaskById/${taskId}`
      );
      setTask(response.data.task);
      setOfferSubmitting(false);
    } catch (error) {
      console.error("Error submitting offer:", error);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
      >
        <div className="p-4">
          {loading ? (
            <div className="flex items-center justify-center">
              <FaSpinner className="text-blue-500 animate-spin text-4xl" />
              <span className="text-blue-500 text-lg ml-2">
                Loading details...
              </span>
            </div>
          ) : (
            <div>
              <div className="flex justify-between">
                <div>
                  <h2 className="text-2xl font-bold mb-2">{task?.title}</h2>
                  <div className="flex items-center">
                    {task?.createdBy?.profileImage ? (
                      <img
                        src={task?.createdBy?.profileImage}
                        alt="Profile"
                        className="w-8 h-8 mr-2 rounded-full"
                      />
                    ) : (
                      <FaUser className="text-gray-600 mr-2" />
                    )}
                    <p className="mt-1 text-gray-600 text-sm">
                      <strong>{task?.createdBy?.name}</strong>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaMapMarkerAlt className="text-green-500 mr-1" />
                    <p className="mt-1 text-sm">
                      <strong></strong> {task?.location || "Remote"}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaCalendarAlt className="text-orange-500 mr-1" />
                    <p className="mt-1 text-sm">
                      <strong></strong>{" "}
                      {task?.date
                        ? new Date(task.date).toLocaleString()
                        : "Flexible"}
                    </p>
                  </div>
                  <p className="mt-1 text-sm">
                    <strong>${task?.originalBudget} AUD</strong>
                  </p>
                  <p className="mt-1 text-gray-600 text-sm">
                    {task?.description}
                  </p>
                </div>
                <div className="flex justify-center items-center hidden lg:block">
                  <div className="bg-gray-300 p-10 rounded-lg shadow-lg w-full max-w-md text-center">
                    <p className="text-5xl font-bold text-gray-800">
                      ${task?.originalBudget}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-4 flex">
                <button
                  className={`mr-2 px-6 py-2 rounded-t-lg text-sm font-medium transition-all duration-300 ease-in-out ${
                    activeTab === "offers"
                      ? "bg-blue-500 text-white border-b-2 border-blue-700"
                      : "bg-gray-200 text-blue-500 hover:bg-gray-300"
                  }`}
                  onClick={() => handleTabChange("offers")}
                >
                  Offers
                </button>

                <button
                  className={`px-6 py-2 rounded-t-lg text-sm font-medium transition-all duration-300 ease-in-out ${
                    activeTab === "questions"
                      ? "bg-blue-500 text-white border-b-2 border-blue-700"
                      : "bg-gray-200 text-blue-500 hover:bg-gray-300"
                  }`}
                  onClick={() => handleTabChange("questions")}
                >
                  Questions
                </button>
              </div>

              {activeTab === "offers" ? (
                <div className="mt-2">
                  {task?.offers.length > 0 ? (
                    task.offers.map((offer) => (
                      <div
                        key={offer._id}
                        className="flex items-start border-b py-1"
                      >
                        <div className="mr-2 ">
                          {offer.offeredBy.profileImage ? (
                            <img src={offer.offeredBy.profileImage} alt="Profile" className="w-8 h-8 mr-1  mt-2 rounded-full" />
                          ) : (
                            <FaUserCircle
                              className="text-blue-500 mt-2"
                              size={25}
                            />
                          )                            
                          } 
                        </div>
                        <div className="flex-grow bg-gray-100 rounded-lg p-2">
                          <p className="text-xs text-gray-500">
                            {offer.offeredBy.name}
                          </p>
                          <p
                            className="text-sm"
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            <OfferMessage
                              message={offer.message || "No message provided."}
                            />
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm">
                      No offers yet. Be the first to offer!
                    </p>
                  )}
                </div>
              ) : (
                <div className="mt-2">
                  <p className="text-xs text-red-600 mt-2 shadow-sm">
                    Do not post any personal information here. This is visible
                    to all users.
                  </p>
                  {task?.questions.length > 0 ? (
                    task.questions.map((question) => (
                      <div
                        key={question._id}
                        className="flex items-start border-b py-1"
                      >
                        <div className="mr-2">
                          {question.askedBy.profileImage ? (
                            <img src={question.askedBy.profileImage} alt="Profile" className="w-8 h-8 mr-1  mt-2 rounded-full" />
                          ) : (
                            <FaUserCircle
                              className="text-blue-500 mt-2"
                              size={25}
                            />
                          )
                            
                          } 
                        </div>
                        <div className="flex-grow bg-gray-100 rounded-lg p-2">
                          <p className="text-xs text-gray-500">
                            {question.askedBy.name}
                          </p>
                          <p className="text-sm">
                            <strong></strong> {question.question}
                          </p>
                          <button
                            onClick={() => toggleAnswerVisibility(question._id)}
                            className="text-blue-500 text-sm mt-1 focus:outline-none"
                          >
                            {shownAnswers[question._id]
                              ? "Hide Answer"
                              : "View Reply"}
                          </button>
                          {shownAnswers[question._id] && (
                            <p className="text-sm">
                              <strong></strong>{" "}
                              {question.answer || "No answer yet."}
                            </p>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm">No questions yet.</p>
                  )}

                  <div className="mt-4 flex">
                    <input
                      type="text"
                      value={newQuestion}
                      onChange={(e) => setNewQuestion(e.target.value)}
                      placeholder="Ask your question..."
                      className="border rounded p-2 w-full text-sm"
                    />
                    <button
                      onClick={handleQuestionSubmit}
                      className="ml-2 bg-blue-500 text-white px-4 py-2 rounded text-sm"
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}

              <div className="flex mt-4">
                {task?.status === "open" && (
                  <button
                    onClick={handleMakeOfferClick}
                    className="bg-green-600 text-white px-4 py-2 rounded"
                    disabled={checkingUsersMakeOfferRequirements}
                  >
                    {checkingUsersMakeOfferRequirements
                      ? "Checking requirements.. "
                      : "Make Offer"}
                  </button>
                )}

                <button
                  onClick={onRequestClose}
                  className="bg-gray-500 ml-3 text-white px-4 py-2 rounded"
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={isOfferModalOpen}
        onRequestClose={() => setIsOfferModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="bg-white rounded-lg shadow-lg p-6">
          <FaDollarSign className="text-4xl text-blue-500 mx-auto" />
          <h2 className="text-2xl font-bold mb-4 text-center">
            Confirm Your Offer
          </h2>

          <p className="mb-2 text-1xl font-semibold text-gray-600">
            Enter your offer amount:
          </p>
          <input
            type="number"
            value={offerAmount}
            placeholder="Offer Amount"
            className="border border-gray-300 bg-white rounded p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
            onChange={(e) => setOfferAmount(e.target.value)}
          />
          <div className="mt-1">
            <p className=" text-gray-800">
              Original Budget:{" "}
              <span className="text-green-600">${task?.originalBudget}</span>
            </p>
            <p className="  text-gray-800">
              Skilled Mates Fee (10%):{" "}
              <span className="text-red-600">
                ${(offerAmount * platformFeePercentage).toFixed(2)}
              </span>
            </p>
            <p className=" text-gray-800">
              You will get:{" "}
              <span className="text-blue-600">
                $
                {(
                  parseFloat(offerAmount) -
                  offerAmount * platformFeePercentage
                ).toFixed(2)}
              </span>
            </p>
          </div>

          <p className="text-1xl mt-4 font-semibold text-gray-600">
            Write a offer message:
          </p>
          <textarea
            value={offerMessage}
            placeholder="With 10 years of experience in this industry, I'm ready to help you achieve your goals with expertise and dedication."
            maxLength="500"
            className="border border-gray-300 bg-white rounded p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 "
            onChange={(e) => setOfferMessage(e.target.value)}
          />
          <div className="flex justify-between">
            <p
              className={`text-sm ${
                offerMessage.trim().length > 0
                  ? "text-gray-500"
                  : "text-red-400"
              }`}
            >
              Must write a message
            </p>
            <p className="text-sm text-gray-500">
              {offerMessage.length}/500 characters left
            </p>
          </div>

          {/* Details Section */}
          <div className="mt-4">
            <h3 className="font-bold text-lg mt-4">Important</h3>
            <details className="mb-4">
              <summary className="cursor-pointer text-blue-600 hover:underline">
                Make sure you understand the Scope of Work
              </summary>
              <p className="text-gray-700 mb-2">
                SkilledMates only connects users. Please ensure you fully
                understand the scope of the work before proceeding. Users cannot
                charge more if the scope of work changes unless agreed upon in
                advance. SkilledMates is not responsible for any damages or
                issues arising from agreements made between users.
              </p>
            </details>

            <details className="mb-4">
              <summary className="cursor-pointer text-blue-600 hover:underline">
                Skilled Mates will not be responsible for any disputes
              </summary>
              <p className="text-gray-700 mb-2">
                By submitting your offer, you acknowledge that you have read and
                agree to the terms of service and any legal obligations
                associated with this work.
              </p>
              <p className="text-gray-700 mb-2">
                Skilled Mates only provides a platform to connect users. We are
                not responsible for any damage, injury, fraud, loss, or other
                risks that may arise from interactions, agreements, or
                transactions made through our platform. Any engagement with
                other users is done at your own risk.
              </p>
              <p className="text-gray-700 mb-2">
                Please ensure you understand the full scope of the work, as you
                will not be able to charge more if the scope of work changes
                unless mutually agreed upon with the other party.
              </p>
              <p className="text-gray-700 mb-2">
                We strongly recommend performing due diligence and, if needed,
                seeking independent legal advice before entering into any
                agreements.
              </p>
            </details>

            <label className="flex items-center mt-2 mb-4">
              <input
                type="checkbox"
                checked={underStantScopeOfWork}
                onChange={(e) => setUnderStantScopeOfWork(e.target.checked)}
                required
                className="mr-2 h-4 w-4 border-gray-300 rounded focus:ring-blue-500"
              />
              <span className="text-gray-700">
                I understand the scope of work and the associated legal
                considerations.{" "}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:underline ml-1"
                >
                  Terms and Conditions
                </a>
              </span>
            </label>
          </div>

          <div className="mt-6 flex justify-between">
            <button
              onClick={() => {
                // Handle the offer submission here
                // Example: Submit offer to the API
                submitOffer();
              }}
              className={`${
                offerValidated
                  ? "bg-green-600 text-white"
                  : "bg-gray-300 text-gray-600"
              } px-4 py-2 rounded`}
              disabled={!offerValidated || offerSubmitting}
            >
              {offerSubmitting ? "Submitting..." : "Submit Offer"}
            </button>
            <button
              onClick={() => setIsOfferModalOpen(false)}
              className="bg-gray-500 ml-3 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TaskDetailsModal;
