import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance';

const FeedbackList = () => {
  const [feedbacks, setFeedbacks] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [otherInformation, setOtherInformation] = useState({});

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await axiosInstance.get('/api/feedback');
        setFeedbacks(response.data);

        const otherInformationResponse = await axiosInstance.get('/api/feedback/otherInformation');
        setOtherInformation(otherInformationResponse.data);

      } catch (error) {
        console.error('Error fetching feedback:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">All Feedback</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {feedbacks.map((feedback) => (
          <div key={feedback._id} className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-2">{feedback.name}</h2>
            <p className="text-gray-700 mb-2"><strong>Email:</strong> {feedback.email}</p>
            <p className="text-gray-700 mb-4">{feedback.message}</p>
            <p className={`text-sm font-semibold p-2 rounded ${feedback.status === 'new' ? 'bg-yellow-200' : 'bg-green-200'}`}>
              Status: {feedback.status}
            </p>
          </div>
        ))}
      </div>

      <h1 className="text-2xl font-bold mb-4">Other information</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-2">Total users</h2>
          <p className="text-gray-700 mb-4">{otherInformation.totalUsers}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-2">Total workers</h2>
          <p className="text-gray-700 mb-4">{otherInformation.totalWorkers}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-2">Total employers</h2>
          <p className="text-gray-700 mb-4">{otherInformation.totalEmployers}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-2">Total users with both roles</h2>
          <p className="text-gray-700 mb-4">{otherInformation.totalBoth}</p>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-bold mb-2">Total reset passwords</h2>
          <p className="text-gray-700 mb-4">{otherInformation.totalResetPasswords}</p>
        </div>
      </div>
    </div>
  );
};

export default FeedbackList;
