import React, { useState, useEffect } from "react";
import axiosInstance from "../../axiosInstance";
import { BounceLoader } from "react-spinners";
import {
  FaSun,
  FaCloudSun,
  FaCloud,
  FaMoon,
  FaTimes,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";

const PostTask = ({ closeButtonClicked,closeButtonAndViewThePostedTaskClicked }) => {
  const [step, setStep] = useState(1);
  const [showTimeOptions, setShowTimeOptions] = useState(false);
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [showAddressDropdown, setShowAddressDropdown] = useState(false);
  const [fullAddressSelected, setFullAddressSelected] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    images: [],
    originalBudget: "",
    location: "",
    fullAddress: "",
    dueDate: "",
    dueTime: "",
    isFlexible: false,
    isThisRemoteTask: false,
  });

  const [suburbs, setSuburbs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [goolgeAddress, setGoogleAddress] = useState([]);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setFormData({ ...formData, images: files });
  };

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    //submit task here using api
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("originalBudget", formData.originalBudget);
    formDataToSend.append("location", formData.location);
    formDataToSend.append("fullAddress", formData.fullAddress);
    formDataToSend.append("dueDate", formData.dueDate);
    formDataToSend.append("dueTime", formData.dueTime);
    formDataToSend.append("isFlexible", formData.isFlexible);
    formDataToSend.append("isThisRemoteTask", formData.isThisRemoteTask);
    formData.images.forEach((image) => {
      formDataToSend.append("images", image);
    });

    try {
      setIsSubmiting(true);
      await axiosInstance.post("/api/tasks/postTask", formDataToSend);
      taskSubmittedSuccessfully();
    } catch (error) {
      taskSubmissionFailed();
    }
  };

  const taskSubmittedSuccessfully = () => {
    setIsSubmiting(false);
    setSubmitSuccess(true);
  };
  const taskSubmissionFailed = () => {
    setIsSubmiting(false);
    setSubmitSuccess(false);
  };

  useEffect(() => {
    const fetchSuburbs = async () => {
      if (formData.location.length > 0) {
        setLoading(true);
        try {
          const response = await axiosInstance.get(`/api/suburbs`, {
            params: { query: formData.location },
          });
          setSuburbs(response.data);
          setShowDropdown(true);
        } catch (error) {
          console.error("Error fetching suburb data:", error);
          setSuburbs([]);
          setShowDropdown(false);
        } finally {
          setLoading(false);
        }
      } else {
        setShowDropdown(false);
      }
    };

    const debounceFetch = setTimeout(() => {
      fetchSuburbs();
    }, 300); // Debounce time in milliseconds

    return () => clearTimeout(debounceFetch); // Clear timeout on unmount
  }, [formData.location]);

  // Dynamic heading and subheading based on step
  const getStepHeading = () => {
    switch (step) {
      case 1:
        return {
          title: "Start with a title",
          subtitle: "In a few words, tell us what you need done.",
        };
      case 2:
        return {
          title: "Set a budget and location",
          subtitle:
            "Specify how much you’re willing to pay and the location of the task.",
        };
      case 3:
        return {
          title: "Due date and flexibility",
          subtitle:
            "Set the task due date and let us know if the date is flexible.",
        };
      case 4:
        return {
          title: "Finally",
          subtitle: "Submit your task. Someone will make an offer soon.",
        };
      default:
        return { title: "", subtitle: "" };
    }
  };

  const handleSelect = (suburb) => {
    setFormData((prevData) => ({ ...prevData, location: suburb }));
    setShowDropdown(false);
  };

  const { title: headingTitle, subtitle: headingSubtitle } = getStepHeading();

  // Step validation
  const isStep1Valid =
    formData.title.trim() !== "" && formData.description.trim() !== "";
  const isStep2Valid =
    formData.originalBudget !== "" &&
    ((formData.location !== "" && fullAddressSelected) || formData.isThisRemoteTask);
  const handleTimeSelection = (time) => {
    setFormData({ ...formData, dueTime: time });
  };
  //
  const isStep3Valid =
    (formData.dueDate.trim() !== "" || formData.isFlexible) &&
    (!showTimeOptions || formData.dueTime.trim() !== "");

  const handleAddressChange = async (e) => {
    if (e.target.value.length > 0) {
      setFormData({ ...formData, fullAddress: e.target.value });

      setAddressLoading(true);
      setFullAddressSelected(false);
      try {
        const response = await axiosInstance.get(`/api/google/autocomplete`, {
          params: { query: e.target.value },
        }); 
        setGoogleAddress(response.data.predictions);
        setShowAddressDropdown(true);
      } catch (error) {
        console.error("Error fetching suburb data:", error);
        setGoogleAddress([]);
        setShowDropdown(false);
      } finally {
        setAddressLoading(false);
      }
    } else {
      setShowDropdown(false);
    }
  }; 
 

  const handleSelectAddress = (address) => {
    setFormData({ ...formData, fullAddress: address.description });
    setShowAddressDropdown(false);
    setFullAddressSelected(true);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-6 max-w-lg mx-auto space-y-4 bg-white rounded-lg shadow-lg relative "
      style={{ width: "95%", maxWidth: "700px" }}
    >
      <button
        onClick={closeButtonClicked}
        className="bg-red-400 text-white px-4 py-2 rounded absolute top-4 right-4"
      >
        <FaTimes />
      </button>

      <div className="flex items-center justify-center">
        <span className="text-gray-600 ">Step {step} of 4</span>
      </div>

      <h2 className="text-2xl font-semibold text-center">{headingTitle}</h2>
      {submitSuccess === false &&
        submitError === false &&
        isSubmitting === false && (
          <p className="text-gray-600 text-center mb-4">{headingSubtitle}</p>
        )}

      {/* Step 1: Task Details */}
      {step === 1 && (
        <>
          <div>
            <label className="block font-medium">Task Title</label>
            <input
              type="text"
              value={formData.title}
              placeholder="E.g. Help assembling a new desk"
              maxLength="50"
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              required
              className=" bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
            />
            <span className="text-sm text-gray-500">Max 50 characters</span>
          </div>
          <div>
            <label className="block font-medium">Task Description</label>
            <textarea
              value={formData.description}
              maxLength={500}
              placeholder="Describe your task in detail. Do not share any personal information here."
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              required
              className=" bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 p-3 w-full"
            />
            <span className="text-sm text-gray-500">Max 500 characters</span>
          </div>
          <button
            type="button"
            onClick={nextStep}
            className={`text-white px-4 py-2 rounded w-full mt-4 ${
              !isStep1Valid ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-700"
            }`}
            disabled={!isStep1Valid} // Disable until Step 1 is completed
          >
            Next
          </button>
        </>
      )}

      {/* Step 2: Budget and Location */}
      {step === 2 && (
        <>
          <div>
            <label className="block font-medium">Your Budget</label>
            <input
              type="number"
              value={formData.originalBudget}
              onChange={(e) =>
                setFormData({ ...formData, originalBudget: e.target.value })
              }
              required
              placeholder="Enter the amount"
              className=" w-full bg-white text-black border p-3 border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex items-center space-x-2 mt-4">
            <input
              type="checkbox"
              checked={formData.isThisRemoteTask}
              onChange={(e) =>
                setFormData({ ...formData, isThisRemoteTask: e.target.checked })
              }
             className="mr-2 transform scale-150 bg-white border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <label>This is a Remote task</label>
          </div>
          {!formData.isThisRemoteTask && (
            <>
              <div>
                <label className="block font-medium">
                Suburb Location (visible to everyone)
                </label>
                <input
                  type="text"
                  name="location"
                  placeholder="Start typing..."
                  value={formData.location}
                  disabled={formData.isThisRemoteTask}
                  onChange={(e) =>
                    setFormData({ ...formData, location: e.target.value })
                  }
                  required
                  className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <label className="block text-sm text-gray-500">
                  This location name will be visible to all taskers.
                </label>
                {showDropdown && (
                  <div className="relative">
                    <div className="absolute z-10 bg-white border border-gray-300 rounded shadow-lg w-full mt-1 max-h-60 overflow-auto">
                      {loading ? (
                        <div className="p-2 text-center">
                          <BounceLoader color="#000" />
                        </div>
                      ) : suburbs.length > 0 ? (
                        suburbs.map((suburb) => (
                          <div
                            key={suburb.Suburb}
                            className="p-2 cursor-pointer hover:bg-gray-200 text-black"
                            onClick={() => handleSelect(suburb)}
                          >
                            {suburb}
                          </div>
                        ))
                      ) : (
                        <div className=" "> </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div>
                <label className="block font-medium">Full Address</label>
                <input
                  type="text"
                  name="fullAddress"
                  placeholder="Enter full address"
                  value={formData.fullAddress}
                  onChange={handleAddressChange}
                  required
                  className="w-full bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <lebel className="block text-sm text-gray-500">
                  This address will be shared with the tasker once the task is
                  assigned.
                </lebel>
                {showAddressDropdown && !fullAddressSelected  && (
                  <div className="relative">
                    <div className="absolute z-10 bg-white border border-gray-300 rounded shadow-lg w-full mt-1 max-h-60 overflow-auto">
                      {addressLoading ? (
                        <div className="p-2 text-center">
                          <BounceLoader color="#000" />
                        </div>
                      ) : goolgeAddress.length > 0 ? (
                        goolgeAddress.map((address) => (
                          <div
                            key={address.description}
                            className="p-2 cursor-pointer hover:bg-gray-200 text-black"
                            onClick={() => handleSelectAddress(address)}
                          >
                            {address.description}
                          </div>
                        ))
                      ) : (
                        <div className=" "> </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          <div className="flex justify-between mt-4">
            <button
              type="button"
              onClick={prevStep}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Back
            </button>
            <button
              type="button"
              onClick={nextStep}
              className={`text-white px-4 py-2 rounded ${
                !isStep2Valid ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-700"
              }`}
              disabled={!isStep2Valid} // Disable until Step 2 is completed
            >
              Next
            </button>
          </div>
        </>
      )}

      {/* Step 3: Due Date and Flexibility */}
      {step === 3 && (
        <>
          <div>
            <label className="block font-medium">Due Date</label>
            <input
              type="date"
              value={formData.dueDate}
              min={new Date().toISOString().split("T")[0]}
              onChange={(e) =>
                setFormData({ ...formData, dueDate: e.target.value })
              }
              className=" bg-white text-black border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 p-3 w-full"
            />
          </div>

          <div className="flex items-center space-x-2 mt-4">
            <input
              type="checkbox"
              checked={formData.isFlexible}
              onChange={(e) =>
                setFormData({ ...formData, isFlexible: e.target.checked })
              }
              className="mr-2 transform scale-150 bg-white border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <label>Flexible with the due date</label>
          </div>
          <div className="flex items-center space-x-2 mt-4">
            <input
              type="checkbox"
              checked={showTimeOptions}
              onChange={(e) => setShowTimeOptions(e.target.checked)}
             className="mr-2 transform scale-150 bg-white border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <label>I need it certain time of the day</label>
          </div>
          {/* Time Options Cards */}
          {showTimeOptions && (
            <div className="grid grid-cols-2 gap-4 mt-4">
              {/* Morning Option */}
              <div
                onClick={() => handleTimeSelection("Morning")}
                className={`p-4 rounded-lg shadow cursor-pointer flex flex-col items-center ${
                  formData.dueTime === "Morning"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100 text-gray-700"
                }`}
              >
                <FaSun size={24} />
                <span className="mt-2 text-sm font-medium">Morning</span>
                <span className="text-xs">Before 10am</span>
              </div>

              {/* Midday Option */}
              <div
                onClick={() => handleTimeSelection("Midday")}
                className={`p-4 rounded-lg shadow cursor-pointer flex flex-col items-center ${
                  formData.dueTime === "Midday"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100 text-gray-700"
                }`}
              >
                <FaCloudSun size={24} />
                <span className="mt-2 text-sm font-medium">Midday</span>
                <span className="text-xs">10am - 2pm</span>
              </div>

              {/* Afternoon Option */}
              <div
                onClick={() => handleTimeSelection("Afternoon")}
                className={`p-4 rounded-lg shadow cursor-pointer flex flex-col items-center ${
                  formData.dueTime === "Afternoon"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100 text-gray-700"
                }`}
              >
                <FaCloud size={24} />
                <span className="mt-2 text-sm font-medium">Afternoon</span>
                <span className="text-xs">2pm - 6pm</span>
              </div>

              {/* Evening Option */}
              <div
                onClick={() => handleTimeSelection("Evening")}
                className={`p-4 rounded-lg shadow cursor-pointer flex flex-col items-center ${
                  formData.dueTime === "Evening"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100 text-gray-700"
                }`}
              >
                <FaMoon size={24} />
                <span className="mt-2 text-sm font-medium">Evening</span>
                <span className="text-xs">After 6pm</span>
              </div>
            </div>
          )}
          <div className="flex justify-between mt-4">
            <button
              type="button"
              onClick={prevStep}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              Back
            </button>
            <button
              type="button"
              onClick={nextStep}
              className={`text-white px-4 py-2 rounded  mt-4 ${
                !isStep3Valid ? "bg-gray-500" : "bg-blue-500 hover:bg-blue-700"
              }`}
              disabled={!isStep3Valid} // Disable until Step 3 is completed
            >
              Next
            </button>
          </div>
        </>
      )}

      {/* Step 4: Additional Information */}
      {step === 4 && (
        <>
          {/* {submitSuccess === false &&
            submitError === false &&
            isSubmitting === false && (
              <div>
                <label className="block font-medium">
                  Upload Images (Optional)
                </label>
                <input
                  type="file"
                  multiple
                  onChange={handleImageUpload}
                  className="border border-gray-300 p-3 rounded w-full"
                />
              </div>
            )} */}

          {isSubmitting && (
            <div className="flex justify-center items-center space-y-4 mt-4">
              <BounceLoader color="#000" loading={isSubmitting} />
              <p>Submitting your task...</p>
            </div>
          )}

          {submitSuccess && (
            <div className=" items-center justify-center text-center mt-4">
              <div className="items-center flex text-green-600  justify-center">
                <FaCheckCircle size={30} />
              </div>
              <div>
                <p className="text-lg text-green-600 ">
                  Task submitted successfully!
                </p>
                <p className="text-sm text-gray-600">
                  You will be notified when someone makes an offer on your task.
                </p>
              
              </div>
            </div>
          )}

          {submitError && (
            <div className="  items-center justify-center text-red-600 text-center mt-4">
              <FaTimesCircle className="mr-2" />
              Task submission failed. Please try again.
            </div>
          )}

          <div className="flex justify-between mt-4">
            {submitSuccess === false && (
              <button
                type="button"
                onClick={prevStep}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Back
              </button>
            )}

            {submitSuccess === true ? (
              
              <button
                type="button"
                onClick={closeButtonAndViewThePostedTaskClicked} 
                className="bg-red-400 text-white px-4 py-2 rounded order-2"
              >
                Close and View Task
              </button>
              
            ) : (
              <button
                type="submit"
                className="bg-green-500 text-white px-4 py-2 rounded"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit Task"}
              </button>
            )}
          </div>
        </>
      )}

      {/* Loading indicator for suburbs */}
      {loading && <p className="text-center">Loading suburbs...</p>}
    </form>
  );
};

export default PostTask;
