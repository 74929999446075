import React from "react";
import { FaMapMarkerAlt, FaCalendarAlt, FaUser, FaUserCircle, FaUserSlash, FaUserClock, FaUserFriends, FaUserShield, FaUsersCog, FaUserAlt } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";

const TaskCard = ({ task, onOpenModal }) => {
  const timeOfDay = task.date
    ? new Date(task.date).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : "N/A";
  const formattedDate = task.dueDate
    ? new Date(task.dueDate).toLocaleDateString()
    : "N/A";

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Address copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div
      className="bg-white p-4 rounded-lg shadow-md relative cursor-pointer hover:shadow-lg transition-shadow duration-200"
      onClick={() => onOpenModal(task)}
    >
      <div className="absolute top-3 right-2 text-gray-600 font-bold text-2xl whitespace-nowrap ">
        {task.originalBudget && `$${task.originalBudget}`}
        {task.agreedBudget && `$${task.agreedBudget}`}
      </div>
      <div className="pr-[40px]">
        <h2 className="text-lg font-semibold text-gray-800 ">
          {task.title}
        </h2>
      </div>

      <div className="flex items-center mt-2">
        <FaMapMarkerAlt className="text-green-500 mr-1" />
        <span className="text-gray-800 ml-2">
          {task.isThisRemoteTask ? "Remote" : task.location}
          {!task.isThisRemoteTask && task.fullAddress && (
            <span
              className="text-blue-500 font-semibold"
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(task.fullAddress);
              }}
            >
              {" "}
              {task.fullAddress}
            </span>
          )}
        </span>
      </div>

      <div className="flex items-center mt-2">
        {task.dueDate && (
          <>
            <FaCalendarAlt className="text-red-500 mr-1" />
            <span className="text-gray-800 ml-2">{formattedDate} </span>
          </>
        )}
      </div>

      <div className="flex items-center mt-2">
        {task.isFlexible && (
          <>
            <FaCalendarAlt className="text-blue-500 mr-1" />
            <span className="text-gray-800 ml-2">Flexible with dates</span>
          </>
        )}
      </div>

      <div className="flex items-center mt-2">
        {task.dueTime && (
          <>
            <MdAccessTime className="text-red-500 mr-1" />
            <span className="text-gray-800 text-sm ml-2">
               {task.dueTime === "Morning" && "Morning Before 10am "}
                {task.dueTime === "Midday" && "Midday 10am - 12pm"}
              {task.dueTime === "Afternoon" && "Afternoon 2pm - 6pm"}
              {task.dueTime === "Evening" && "Evening After 6pm"}
               
            </span>
          </>
        )}
      </div>

      <div className="flex items-center mt-2">
        <p className="text-green-600 font-bold text-sm ">
          {task.status === "open" && "Open"}
          {task.status === "accepted" && "Accepted"}
          {task.status === "completedByAssignee" && "Completed by assignee"}
          {task.status === "completedByOwner" && "Completed"}
        </p>

        {task.numberOfOffers > 0 && (
          <p className="text-grey-200 text-xs  ml-2 ">
            {task.numberOfOffers} Offers
          </p>
        )}
        {task.numberOfQuestions > 0 && (
          <p className="text-grey-100 text-xs   ml-1 ">
            {task.numberOfQuestions} Questions
          </p>
        )}
        {!task.isThisRemoteTask && task.fullAddress && (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${task.fullAddress}`}
            target="_blank"
            rel="noreferrer"
            className="bg-blue-500 text-white px-4 py-2 ml-4 rounded-lg shadow-sm hover:bg-blue-600"
            onClick={(e) => e.stopPropagation()}
          >
            Directions
          </a>
        )}
      </div>

      <div className="absolute bottom-3 right-1">
        <div className="items-center flex justify-center">
        <span className="text-gray-600 text-xs mr-2 font-semibold ">
          {task.createdBy?.name}
        </span>
        {task.createdBy.profileImage ? (
        <img src={task.createdBy?.profileImage} alt="profile" className="w-8 h-8 mr-1 rounded-full" />
        ) : (
          <FaUserAlt className="text-orange-600 mr-1 " /> 
        )  
        }
       
        

        </div>
      </div>
    </div>
  );
};

export default TaskCard;
