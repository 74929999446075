import React, { useState, useContext, useEffect } from "react";
import AllTasks from "../components/Tasks/AllTasks";
import PostedTasks from "../components/Tasks/PostedTasks";
import TaskAssigned from "../components/Tasks/TaskAssigned";
import OffersPending from "../components/Tasks/OffersPending";
import TaskCompleted from "../components/Tasks/TaskCompleted";
import PostTask from "../components/Tasks/PostTask";

import AuthContext from "../AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom"; // For navigation
import axiosInstance from "../axiosInstance";
import Modal from "react-modal";
import { FaUserFriends } from "react-icons/fa";
import TaskDetailsModal from "../components/Tasks/TaskDetailsModal";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SavePaymentMethod from "../components/Payment/SavePaymentMethod";

const Tasks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { filter, taskId } = useParams();
  const [selectedView, setSelectedView] = useState("allTasks");
  const [signinRegisterModalOpen, setSigninRegisterModalOpen] = useState(false);
  const [postTaskModalOpen, setPostTaskModalOpen] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [taskDetailsModalOpen, setTaskDetailsModalOpen] = useState(false);
  const MySwal = withReactContent(Swal);
  const [checkingRequirmentsToPostTask, setCheckingRequirmentsToPostTask] =
    useState(false);

  const { userRole, isLoggedOut, loggedUser, isSubscriptionRequired, logout } =
    useContext(AuthContext);

  // Define options for the dropdown
  const options = [
    { value: "allTasks", label: "All Tasks" },
    { value: "postedTasks", label: "Posted Tasks" },
    { value: "tasksAssigned", label: " Assigned Task" },
    { value: "completedTasks", label: "Task Completed" },
  ];

  // const options = [
  //   { value: "allTasks", label: "All Tasks" },
  //   { value: "postedTasks", label: "Posted Tasks" },
  //   { value: "tasksAssigned", label: " Assigned Task" },
  //   { value: "OffersPending", label: "Offers Pending" },
  //   { value: "completedTasks", label: "Task Completed" },
  // ];

  // Update selectedView based on URL
  useEffect(() => {
    if (filter) {
      setSelectedView(filter);
    }
    if (taskId) { 
      setSelectedTaskId(taskId);
      openTaskDetailsModal();
    }
  }, [filter, taskId]);

  const openTaskDetailsModal = () => {
    setTaskDetailsModalOpen(true);
  };

  const closeTaskDetailsModal = () => {
    setTaskDetailsModalOpen(false);
    setSelectedTaskId(null);
    navigate("/tasks"); // Navigate back to the main tasks page without taskId
  };

  // Get the selected component based on dropdown value
  const renderSelectedComponent = () => {
    switch (selectedView) {
      case "allTasks":
        return <AllTasks showSignRegisterInModal={showSignRegisterInModal} />;
      case "postedTasks":
        return <PostedTasks />;
      case "tasksAssigned":
        return <TaskAssigned />;
      case "OffersPending":
        return <OffersPending />;
      case "completedTasks":
        return <TaskCompleted />;
      default:
        return <AllTasks />;
    }
  };

  const postATaskClicked = async () => {
    axiosInstance.get("/api/postTaskCount"); 
    setCheckingRequirmentsToPostTask(true);
    if (isLoggedOut) {
      MySwal.fire({
        icon: "info",
        title: "Sign In Required",
        text: "You need to sign in or register to post a task.",
        showCancelButton: true,
        confirmButtonText: "Sign In",
        cancelButtonText: "Close",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/login");
        }
      });
      setCheckingRequirmentsToPostTask(false);
      return;
    }

    try {
      const response = await checkIfUserHasPaymentMethodAdded();
      if (response.data.taskPaymentMethodAdded) {
        showPostTaskModal();
      } else {
        MySwal.fire({
          icon: "info",
          title: "Payment Details Required",
          text: "You need to verify your payment details to proceed.",
          showCancelButton: true,
          confirmButtonText: "Add Payment Details",
          cancelButtonText: "Close",
        }).then((result) => {
          if (result.isConfirmed) {
            const email = loggedUser.email;
            const url = `${process.env.REACT_APP_STRIPE_PAYMENT_VERIFICATION_URL}?prefilled_email=${email}`;
            window.open(url, "_blank");
          }
        });
      }
    } catch (error) {
      console.error("Unexpected error occurred:", error);
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "There was an error checking your payment details. Please try again later.",
      });
    } finally {
      setCheckingRequirmentsToPostTask(false);
    }
  };

  //This is for Account Payment
  // const postATaskClicked = async () => {
  //   setCheckingRequirmentsToPostTask(true);
  //   if (isLoggedOut) {
  //     MySwal.fire({
  //       icon: 'info',
  //       title: 'Sign In Required',
  //       text: 'You need to sign in or register to post a task.',
  //       showCancelButton: true,
  //       confirmButtonText: 'Sign In',
  //       cancelButtonText: 'Close',
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         navigate("/login");
  //       }
  //     });
  //     setCheckingRequirmentsToPostTask(false);
  //     return;
  //   }

  //   try {
  //     const response = await checkIfUserHasPaymentMethodAdded();
  //     if(response.data.taskPaymentMethodAdded) {
  //       showPostTaskModal();
  //     }
  //     if(response.data.url && response.data.url !== '') {
  //       MySwal.fire({
  //         icon: 'info',
  //         title: 'Payment Details Required',
  //         text: 'You need to add your payment details to proceed. A quick setup process will guide you through a few verification steps.',
  //         showCancelButton: true,
  //         confirmButtonText: 'Add Payment',
  //         cancelButtonText: 'Close',
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           // If the user clicked "Add Payment", redirect them to the Stripe account link
  //           window.open(response.data.url, '_blank');
  //         }
  //       });

  //     }
  //   } catch (error) {
  //     console.error("Unexpected error occurred:", error);
  //     MySwal.fire({
  //       icon: 'error',
  //       title: 'Oops...',
  //       text: 'There was an error checking your payment details. Please try again later.',
  //     });
  //   } finally {
  //     setCheckingRequirmentsToPostTask(false);
  //   }

  // };

  const checkIfUserHasPaymentMethodAdded = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/tasks/checkIfUserHasTaskPaymentMethod`
      );

      return response;
    } catch (error) {
      console.error("Unexpected error occurred:", error);
      return null;
    }
  };

  const showPostTaskModal = () => {
    setPostTaskModalOpen(true);
  };
  const closePostTaskModal = () => {
    setPostTaskModalOpen(false);
  };

  const showSignRegisterInModal = () => {
    setSigninRegisterModalOpen(true);
  };
  const closeSignRegisterInModal = () => {
    setSigninRegisterModalOpen(false);
  };

  const TaskCloseButtonClicked = () => {
    closePostTaskModal();
  };

  const CloseModalAndViewThePostedTasks = () => {
    closePostTaskModal();
    navigate("/tasks/view/postedTasks");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center p-4">
       <div className="w-full bg-green-500 text-white py-4 text-center rounded-lg shadow-md mb-4">
      <h2 className="text-xl font-bold">
        🚀 Tasks is a New Feature on Skilled Mates!
      </h2> 
    </div>
      <header className="w-full bg-blue-500 text-white py-8 text-center rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold">Tasks </h1>
        <p className="mt-2 text-lg text-blue-200">
          Post a task, Offer on a task, accept tasks and Make money.
        </p>
      </header>

      {/* Dropdown for Selecting Task Views */}
      <div className="w-full max-w-lg mt-6 mb-10 flex-row ">
        <select
          disabled={isLoggedOut}
          onChange={(e) => setSelectedView(e.target.value)}
          value={selectedView}
          className="  px-4 py-3 bg-white border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <button
          className="bg-blue-500 text-white px-4 py-3 ml-2 rounded-lg shadow-sm "
          onClick={postATaskClicked}
          disabled={checkingRequirmentsToPostTask}
        >
          {checkingRequirmentsToPostTask
            ? "Checking your account..."
            : "Post a Task"}
        </button>
      </div>

      {/* Content based on selected view */}
      <div className="w-full  ">{renderSelectedComponent()}</div>

      <Modal
        isOpen={signinRegisterModalOpen}
        onRequestClose={closeSignRegisterInModal}
        contentLabel="User Action Dialog"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto flex flex-col items-center border-green-500">
          <button
            onClick={closeSignRegisterInModal}
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
          <div className="text-yellow-500 text-4xl mb-4">
            <FaUserFriends />
          </div>
          <h2>
            {" "}
            You need to sign in or register to post a task or offer on a task.
          </h2>
          <div className="flex">
            <button
              onClick={() => navigate("/login")}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
            >
              Sign In
            </button>
            <button
              onClick={() => navigate("/register")}
              className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
            >
              Register
            </button>
            <button
              onClick={closeSignRegisterInModal}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4 ml-4"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={postTaskModalOpen}
        onRequestClose={closePostTaskModal}
        contentLabel="Post a Task"
        className="fixed inset-0 flex items-center justify-center"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <PostTask closeButtonClicked={TaskCloseButtonClicked} closeButtonAndViewThePostedTaskClicked={CloseModalAndViewThePostedTasks} />
      </Modal>

      {selectedTaskId && taskDetailsModalOpen && (
        <TaskDetailsModal
          isOpen={taskDetailsModalOpen}
          onRequestClose={closeTaskDetailsModal}
          taskId={selectedTaskId}
        />
      )}
    </div>
  );
};

export default Tasks;
