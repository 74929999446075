import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import FindWork from "./pages/FindWork";
import FindWorker from "./pages/FindWorker";
import HomePage from "./pages/HomePage";
import FeedBack from "./pages/FeedBack";
import FeedbackList from "./pages/FeedbackList";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Footer from "./components/Footer";
import TermsAndConditions from "./pages/termAndConditions";
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import HowItWorksPage from "./pages/HowItWorks";
import Tasks from "./pages/Tasks";

function App() {
  return (
    <Router>
      <div className="App bg-white">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/find-worker" element={<FindWorker />} />
          <Route path="/find-work" element={<FindWork />} />
          <Route path="/feedback" element={<FeedBack />} />
          <Route path="/feedbacklist" element={<FeedbackList />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/how-it-works" element={<HowItWorksPage />} />
          {/* Route for viewing filtered tasks */}
          <Route path="/tasks/view/:filter" element={<Tasks />} />

          {/* Route for viewing task details */}
          <Route path="/tasks/task/:taskId" element={<Tasks />} />
          <Route path="/tasks" element={<Navigate to="/tasks/view/allTasks" />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
