import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  FaSpinner,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaUser,
  FaUserCircle,
  FaDollarSign,
  FaCross,
  FaTimes,
} from "react-icons/fa";
import axiosInstance from "../../axiosInstance";
import OfferMessage from "./OfferMessage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const TaskDetailsModalForOwner = ({ isOpen, onRequestClose, taskId }) => {
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("offers");
  const [newQuestion, setNewQuestion] = useState("");
  const [shownAnswers, setShownAnswers] = useState({});
  const platformFeePercentage = 0.07; // Assuming 10% platform fee
  const [underStantScopeOfWork, setUnderStantScopeOfWork] = useState(false);
  const [offerMessage, setOfferMessage] = useState("");
  const [offerSubmitting, setOfferSubmitting] = useState(false);
  const [newAnswer, setNewAnswer] = useState({});
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [selectedOfferAmount, setSelectedOfferAmount] = useState(null);
  const [isAcceptedOfferModalOpen, setIsAcceptedOfferModalOpen] =
    useState(false);
  const [underStandScopeOfWork, setUnderStandScopeOfWork] = useState(false);

  const [offerAccepted, setOfferAccepted] = useState(false);
  const [offerAcceptedMessage, setOfferAcceptedMessage] = useState("");

  //m,essgae
  const [newMessage, setNewMessage] = useState("");
  const [isTaskCompleting, setisTaskCompleting] = useState(false);
  const MySwal = withReactContent(Swal);
  const [acceptingOffer, setAcceptingOffer] = useState(false);
  useEffect(() => {
    const fetchTaskDetails = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/tasks/getTaskByIdForOwner/${taskId}`
        );
        setTask(response.data.task);
      } catch (error) {
        console.error("Error fetching task details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchTaskDetails();
    }
  }, [isOpen, taskId]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const toggleAnswerVisibility = (questionId) => {
    setShownAnswers((prevState) => ({
      ...prevState,
      [questionId]: !prevState[questionId],
    }));
  };

  // Function to handle the click on "Make Offer" button
  const handleAcceptOfferClick = () => {
    setIsAcceptedOfferModalOpen(true);
  };

  // Calculate the total fee details
  const calculateFees = (amount) => {
    const fee = amount * platformFeePercentage; // Calculate the fee
    const totalAmount = amount - fee; // Amount that will come to the user
    return { fee, totalAmount };
  };

  const ShowAcceptOfferModal = () => {
    setIsAcceptedOfferModalOpen(true);
  };

  const CloseAcceptOfferModal = () => {
    setIsAcceptedOfferModalOpen(false);
    setOfferAccepted(false);
  };

  const handleAnswerChange = (questionId, value) => {
    setNewAnswer((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  const handleAnswerSubmitForQuestion = async (questionId) => {
    if (newAnswer[questionId]?.trim()) {
      // handleAnswerSubmit(questionId, newAnswer[questionId]); // Submit answer
      try {
        axiosInstance.post(`/api/questions/submitAnswer/${questionId}`, {
          answer: newAnswer[questionId],
        });

        setTask((prev) => ({
          ...prev,
          questions: prev.questions.map((question) =>
            question._id === questionId
              ? { ...question, answer: newAnswer[questionId] }
              : question
          ),
        }));
        setNewAnswer((prev) => ({
          ...prev,
          [questionId]: "",
        }));
      } catch (error) {
        console.error("Error submitting answer:", error);
      }
    }
  };

  const handleAcceptOffer = async (offerId, offerAmount) => {
    setSelectedOfferAmount(offerAmount);
    setSelectedOfferId(offerId);
    setIsAcceptedOfferModalOpen(true);
  };

  const cancelOfferAcceptModal = () => {
    setIsAcceptedOfferModalOpen(false);
    setUnderStandScopeOfWork(false);
  };

  const submitOffer = async () => {
    setOfferSubmitting(true);
    try {
      await axiosInstance.post(`/api/offers/acceptOffer/${selectedOfferId}`);
      // Refresh the task details
      const response = await axiosInstance.get(
        `/api/tasks/getTaskByIdForOwner/${taskId}`
      );
      setOfferAccepted(true);
      setTask(response.data.task);
      setOfferAcceptedMessage("Offer accepted successfully.");
    } catch (error) {
      setOfferAcceptedMessage("Error accepting offer. Please try again.");
      console.error("Error accepting offer:", error);
    }
    setOfferSubmitting(false);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        await axiosInstance.post(`/api/tasks/sendMessage/${taskId}`, {
          message: newMessage,
        });
        setTask((prev) => ({
          ...prev,
          messages: [
            ...prev.messages,
            {
              message: newMessage,
              sender: { name: "You" },
            },
          ],
        }));
        setNewMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  const MarkTaskCompleted = async () => {
    setisTaskCompleting(true);
    try {
      await axiosInstance.post(`/api/tasks/markCompletedByOwner/${taskId}`);
      // Refresh the task details
      const response = await axiosInstance.get(
        `/api/tasks/getTaskById/${taskId}`
      );
      setTask(response.data.task);
      MySwal.fire({
        icon: "success",
        title: "Task Completed",
        text: "Task has been marked as completed.",
      });
    } catch (error) {
      console.error("Error completing task:", error);
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: "Error completing task. Please try again.",
      });
    } finally {
      setisTaskCompleting(false);
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
      >
        <div className="p-4 relative">
          <button
            onClick={onRequestClose}
            className="bg-red-500 ml-3 text-white px-2 py-2 rounded absolute top-1 right-2"
          >
            <FaTimes />
          </button>
          {loading ? (
            <div className="flex items-center justify-center">
              <FaSpinner className="text-blue-500 animate-spin text-4xl" />
              <span className="text-blue-500 text-lg ml-2">
                Loading details...
              </span>
            </div>
          ) : (
            <div>
              <div className="flex justify-between">
                <div>
                  <h2 className="text-2xl font-bold mb-2">{task?.title}</h2>
                  <div className="flex items-center">
                    {task?.createdBy?.profileImage ? (
                      <img
                        src={task?.createdBy?.profileImage}
                        alt="Profile"
                        className="w-8 h-8 mr-2 rounded-full"
                      />
                    ) : (
                      <FaUser className="text-gray-600 mr-2" />
                    )}
                    <p className="mt-1 text-gray-600 text-sm">
                      <strong>{task?.createdBy?.name}</strong>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaMapMarkerAlt className="text-green-500 mr-1" />
                    <p className="mt-1 text-sm">
                      <strong></strong> {task?.location || "Remote"}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaCalendarAlt className="text-orange-500 mr-1" />
                    <p className="mt-1 text-sm">
                      <strong></strong>{" "}
                      {task?.date
                        ? new Date(task.date).toLocaleString()
                        : "Flexible"}
                    </p>
                  </div>
                  <p className="mt-1 text-sm">
                    <strong>${task?.originalBudget} AUD</strong>
                  </p>
                  <p className="mt-1 text-gray-600 text-sm">
                    {task?.description}
                  </p>
                </div>
                <div className="flex justify-center items-center hidden  lg:block">
                  <div className="bg-gray-300 p-10 rounded-lg shadow-lg  mt-7 w-full max-w-md text-center">
                    <p className="text-5xl font-bold text-gray-800">
                      ${task?.originalBudget}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                {task?.status === "completedByAssignee" && (
                  <button
                    className="bg-green-500 shadow-md text-white px-4 py-2 rounded"
                    onClick={MarkTaskCompleted}
                    disabled={isTaskCompleting}
                  >
                    {isTaskCompleting ? "Completing..." : "Mark as Completed"}
                  </button>
                )}
                {task?.status === "completedByOwner" && (
                  <p className="text-green-500 text-sm">Task Completed</p>
                )}
                <div className="mt-4 flex">
                  <button
                    className={`mr-1 px-2 py-2 rounded-t-lg text-sm font-medium transition-all duration-300 ease-in-out ${
                      activeTab === "offers"
                        ? "bg-blue-500 text-white border-b-2 border-blue-700"
                        : "bg-gray-200 text-blue-500 hover:bg-gray-300"
                    }`}
                    onClick={() => handleTabChange("offers")}
                  >
                    Offers
                  </button>

                  <button
                    className={` mr-1 px-2 py-2 rounded-t-lg text-sm font-medium transition-all duration-300 ease-in-out ${
                      activeTab === "questions"
                        ? "bg-blue-500 text-white border-b-2 border-blue-700"
                        : "bg-gray-200 text-blue-500 hover:bg-gray-300"
                    }`}
                    onClick={() => handleTabChange("questions")}
                  >
                    Questions
                  </button>
                  {task?.status !== "open" && (
                    <button
                      className={`px-2 py-2 rounded-t-lg text-sm font-medium transition-all duration-300 ease-in-out ${
                        activeTab === "messages"
                          ? "bg-blue-500 text-white border-b-2 border-blue-700"
                          : "bg-gray-200 text-blue-500 hover:bg-gray-300"
                      }`}
                      onClick={() => handleTabChange("messages")}
                    >
                      Messages
                    </button>
                  )}
                </div>
              </div>

              {activeTab === "offers" && (
                <div className="mt-2">
                  {task?.offers.length > 0 ? (
                    task.offers.map((offer) => (
                      <div
                        key={offer._id}
                        className={`flex items-start border-b py-2 ${
                          offer.isAccepted ? "bg-green-100" : "bg-gray-100"
                        }`}
                      >
                        <div className="mr-2">
                          {offer.offeredBy.profileImage ? (
                            <img
                              src={offer.offeredBy.profileImage}
                              alt="Profile"
                              className="w-8 h-8 mr-1  mt-2 rounded-full"
                            />
                          ) : (
                            <FaUserCircle
                              className="text-blue-500 mt-2"
                              size={25}
                            />
                          )}
                        </div>

                        <div className="flex-grow rounded-lg p-2">
                          <p className="text-xs text-gray-500">
                            {offer.offeredBy.name}
                          </p>
                          <div
                            className="text-sm mb-2"
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            <OfferMessage
                              message={offer.message || "No message provided."}
                            />
                            <div>
                              <strong>Offer Amount: </strong>${offer.budget}
                            </div>
                          </div>

                          {task.status === "open" &&
                            offer.status === "pending" && (
                              <button
                                onClick={() =>
                                  handleAcceptOffer(offer._id, offer.budget)
                                }
                                className="text-sm bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600 focus:outline-none"
                              >
                                {acceptingOffer
                                  ? "Accepting..."
                                  : "Accept Offer"}
                              </button>
                            )}

                          {offer.status === "accepted" && (
                            <span className="text-green-500 font-semibold text-sm">
                              Offer Accepted
                            </span>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm">No offers yet.</p>
                  )}
                </div>
              )}
              {activeTab === "questions" && (
                <div className="mt-2">
                  {task?.questions.length > 0 ? (
                    task.questions.map((question) => (
                      <div
                        key={question._id}
                        className="flex items-start border-b py-1"
                      >
                        <div className="mr-2">
                          {question.askedBy.profileImage ? (
                            <img
                              src={question.askedBy.profileImage}
                              alt="Profile"
                              className="w-8 h-8 mr-1  mt-2 rounded-full"
                            />
                          ) : (
                            <FaUserCircle
                              className="text-blue-500 mt-2"
                              size={25}
                            />
                          )}
                        </div>
                        <div className="flex-grow bg-gray-100 rounded-lg p-2">
                          <p className="text-xs text-gray-500">
                            {question.askedBy.name}
                          </p>
                          <p className="text-sm">
                            <strong>Question: </strong> {question.question}
                          </p>
                          {question.answer ? (
                            <p className="text-sm">
                              <strong>Answer: </strong> {question.answer}
                            </p>
                          ) : (
                            <div className="mt-2">
                              <input
                                type="text"
                                value={newAnswer[question._id] || ""}
                                placeholder="Answer the question"
                                className="border text-sm border-gray-300 bg-white rounded p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
                                onChange={(e) =>
                                  handleAnswerChange(
                                    question._id,
                                    e.target.value
                                  )
                                }
                              />
                              <button
                                onClick={() =>
                                  handleAnswerSubmitForQuestion(question._id)
                                }
                                className="bg-blue-500 text-white px-4 py-1 rounded text-sm mt-2"
                              >
                                Send
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm">No questions yet.</p>
                  )}
                </div>
              )}

              {activeTab === "messages" && (
                <div className="mt-2">
                  {task?.messages.length > 0 ? (
                    task.messages.map((message) => (
                      <div
                        key={message._id}
                        className="flex items-start border-b py-2"
                      >
                        <div className="mr-2">
                          {message.sender.profileImage ? (
                            <img
                              src={message.sender.profileImage}
                              alt="Profile"
                              className="w-8 h-8 mr-1  mt-2 rounded-full"
                            />
                          ) : (

                          <FaUserCircle
                            className="text-blue-500 mt-2"
                            size={25}
                          />
                          )}
                        </div>

                        <div className="flex-grow bg-gray-100 rounded-lg p-2">
                          <p className="text-xs text-gray-500">
                            {message.sender.name} {/* Display sender name */}
                          </p>
                          <div
                            className="text-sm mb-2"
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            <p>{message.message}</p>{" "}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <p className="text-sm mb-2">
                        No messages yet. Start the conversation!
                      </p>
                    </div>
                  )}
                  <div className="mt-2">
                    {task.status !== "completedByOwner" && (
                      <>
                        <input
                          type="text"
                          value={newMessage || ""}
                          placeholder="Send a message"
                          className="border border-gray-300 bg-white rounded p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
                          onChange={(e) => setNewMessage(e.target.value)}
                        />
                        <button
                          onClick={() => handleSendMessage()}
                          className="bg-blue-500 text-white px-4 py-1 rounded text-sm"
                        >
                          Send
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={isAcceptedOfferModalOpen}
        onRequestClose={CloseAcceptOfferModal}
        ariaHideApp={false}
      >
        <div className="bg-white rounded-lg shadow-lg p-6">
          <FaDollarSign className="text-4xl text-blue-500 mx-auto" />
          <h2 className="text-2xl font-bold mb-4 text-center">
            Accept the Offer
          </h2>

          <div className="mt-1">
            <p className="text-gray-800">
              Offer Amount:{" "}
              <span className="text-blue-600">${selectedOfferAmount}</span>
            </p>
          </div>

          {/* Important Information Section */}
          <div className="mt-4">
            <h3 className="font-bold text-lg mt-4">Important Information</h3>
            <p className="text-gray-700 mt-2 mb-4">
              By accepting this offer, you acknowledge that the offer amount
              will be held in our account. Once the task is completed, you must
              mark the task as complete to release the payout to the tasker.
              Skilled Mates facilitates connections between users and is not
              responsible for any disputes or issues arising from this
              transaction.
            </p>
            <details className="mb-4">
              <summary className="cursor-pointer text-blue-600 hover:underline">
                Ensure You Understand Your Responsibility as the Task Owner
              </summary>
              <p className="text-gray-700 mb-2">
                As the task owner, by accepting this offer, you are confirming
                that you fully understand the terms of the agreement and the
                scope of work as outlined. You are responsible for ensuring that
                the task description and expectations are clear and agreed upon
                by both parties.
              </p>
              <p className="text-gray-700 mb-2">
                SkilledMates only connects users. Any agreements, payments, or
                disputes that arise are solely between you and the person
                completing the task. SkilledMates is not responsible for any
                damages, disagreements, or failure to deliver the agreed-upon
                service.
              </p>
              <p className="text-gray-700 mb-2">
                By proceeding, you agree that the offer amount will be deducted
                from your account, and you will honor the agreed terms of the
                task.
              </p>
            </details>
            <details className="mb-4">
              <summary className="cursor-pointer text-blue-600 hover:underline">
                Skilled Mates Is Not Responsible for Any Disputes
              </summary>
              <p className="text-gray-700 mb-2">
                By accepting this offer, you acknowledge and agree to the terms
                of service. SkilledMates is not liable for any damages, losses,
                or issues that may arise from interactions, agreements.
              </p>
            </details>

            <label className="flex items-center mt-2 mb-4">
              <input
                type="checkbox"
                checked={underStandScopeOfWork}
                onChange={(e) => setUnderStandScopeOfWork(e.target.checked)}
                required
                className="mr-2 h-4 w-4 border-gray-300 rounded focus:ring-blue-500"
              />
              <div >
                <span className="text-gray-700">I accept the </span>{" "}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:underline ml-1"
                >
                  Terms and Conditions
                </a>
              </div>
            </label>
          </div>
          {offerAcceptedMessage.trim().length > 0 && (
            <p className="text-red-500 text-sm">{offerAcceptedMessage}</p>
          )}

          <div className="mt-6 flex justify-between">
            {!offerAccepted && (
              <button
                onClick={() => submitOffer()}
                className={`${
                  underStandScopeOfWork
                    ? "bg-green-600 text-white"
                    : "bg-gray-300 text-gray-600"
                } px-4 py-2 rounded`}
                disabled={!underStandScopeOfWork || offerSubmitting}
              >
                {offerSubmitting ? "Submitting..." : "Accept Offer"}
              </button>
            )}

            <button
              onClick={cancelOfferAcceptModal}
              className="bg-gray-500 ml-3 text-white px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TaskDetailsModalForOwner;
