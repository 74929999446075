import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  FaSpinner,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaUser,
  FaUserCircle,
  FaDollarSign,
  FaCross,
  FaTimes,
} from "react-icons/fa";
import axiosInstance from "../../axiosInstance";
import OfferMessage from "./OfferMessage";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const TaskDetailsModalForAssigned = ({ isOpen, onRequestClose, taskId }) => {
  const [task, setTask] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newQuestion, setNewQuestion] = useState("");
  const [shownAnswers, setShownAnswers] = useState({});
  const platformFeePercentage = 0.07; // Assuming 10% platform fee
  const [underStantScopeOfWork, setUnderStantScopeOfWork] = useState(false);
  const [offerMessage, setOfferMessage] = useState("");
  const [offerSubmitting, setOfferSubmitting] = useState(false);
  const [newAnswer, setNewAnswer] = useState({});
  const [selectedOfferId, setSelectedOfferId] = useState(null);
  const [selectedOfferAmount, setSelectedOfferAmount] = useState(null);
  const [isCompleteTaskModalOpen, setIsCompleteTaskModalOpen] = useState(false);
  const [underStandScopeOfWork, setUnderStandScopeOfWork] = useState(false);

  const [offerAccepted, setOfferAccepted] = useState(false);
  const [offerAcceptedMessage, setOfferAcceptedMessage] = useState("");
  const [activeTab, setActiveTab] = useState("messages");
  const [taskCompleted, setTaskCompleted] = useState(false);
  const MySwal = withReactContent(Swal);
  const [isTaskCompleting, setisTaskCompleting] = useState(false);

  //Mesages
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    const fetchTaskDetails = async () => { 
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `/api/tasks/getTaskByIdForAssignee/${taskId}`
        );
        setTask(response.data.task);
      } catch (error) {
        console.error("Error fetching task details:", error);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchTaskDetails();
    }
  }, [isOpen, taskId]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const markTaskAsCompleted = async () => {
    try {
      setisTaskCompleting(true);
      await axiosInstance.post(`/api/tasks/markCompletedByAssignee/${taskId}`);
      setTaskCompleted(true);
      MySwal.fire({
        title: "Task Completed",
        text: "Task has been marked as completed. Please ask task owner to confirm. If owner does not confirm within 24 hours, task will be marked as completed automatically and you will receive payment in 2-3 business days.",
        icon: "success",
      });
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error marking task as completed. Please try again.",
        icon: "error",
      });

      console.error("Error marking task as completed:", error);
    }
    setisTaskCompleting(false);
  };

  const handleCompleteTaskClick = () => {
    MySwal.fire({
      title: "Complete Task Confirmation",
      text: "Are you sure you have completed the task?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        markTaskAsCompleted(); // Call the API or handle confirmation
      }
    });
  };

  const closeCompleteTaskModal = () => {
    setIsCompleteTaskModalOpen(false);
    setOfferAccepted(false);
  };

  const handleAnswerChange = (questionId, value) => {
    setNewAnswer((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  const handleAnswerSubmitForQuestion = async (questionId) => {
    if (newAnswer[questionId]?.trim()) {
      // handleAnswerSubmit(questionId, newAnswer[questionId]); // Submit answer
      try {
        axiosInstance.post(`/api/questions/submitAnswer/${questionId}`, {
          answer: newAnswer[questionId],
        });

        setTask((prev) => ({
          ...prev,
          questions: prev.questions.map((question) =>
            question._id === questionId
              ? { ...question, answer: newAnswer[questionId] }
              : question
          ),
        }));
        setNewAnswer((prev) => ({
          ...prev,
          [questionId]: "",
        }));
      } catch (error) {
        console.error("Error submitting answer:", error);
      }
    }
  };

  const handleAcceptOffer = async (offerId, offerAmount) => {
    setSelectedOfferAmount(offerAmount);
    setSelectedOfferId(offerId);
    setIsCompleteTaskModalOpen(true);
  };

  const cancelOfferAcceptModal = () => {
    setIsCompleteTaskModalOpen(false);
    setUnderStandScopeOfWork(false);
  };

  const submitOffer = async () => {
    setOfferSubmitting(true);
    try {
      await axiosInstance.post(`/api/offers/acceptOffer/${selectedOfferId}`);
      // Refresh the task details
      const response = await axiosInstance.get(
        `/api/tasks/getTaskById/${taskId}`
      );
      setOfferAccepted(true);
      setTask(response.data.task);
      setOfferAcceptedMessage("Offer accepted successfully.");
    } catch (error) {
      setOfferAcceptedMessage("Error accepting offer. Please try again.");
      console.error("Error accepting offer:", error);
    }
    setOfferSubmitting(false);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        await axiosInstance.post(`/api/tasks/sendMessage/${taskId}`, {
          message: newMessage,
        });
        setTask((prev) => ({
          ...prev,
          messages: [
            ...prev.messages,
            {
              message: newMessage,
              sender: { name: "You" },
            },
          ],
        }));
        setNewMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaHideApp={false}
      >
        <div className="p-4 relative">
          <button
            onClick={onRequestClose}
            className="bg-red-500 ml-3 text-white px-2 py-2 rounded absolute top-1 right-2"
          >
            <FaTimes />
          </button>
          {loading ? (
            <div className="flex items-center justify-center">
              <FaSpinner className="text-blue-500 animate-spin text-4xl" />
              <span className="text-blue-500 text-lg ml-2">
                Loading details...
              </span>
            </div>
          ) : (
            <div>
              <div className="flex justify-between">
                <div>
                  <h2 className="text-2xl font-bold mb-2">{task?.title}</h2>
                  <div className="flex items-center">
                    {task?.createdBy?.profileImage ? (
                      <img src={task?.createdBy?.profileImage} alt="Profile" className="w-8 h-8 rounded-full" />
                    ) : (
                    <FaUser className="text-gray-600 mr-2" />
                    )}
                    <p className="mt-1 text-gray-600 text-sm">
                      <strong>{task?.createdBy?.name}</strong>
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaMapMarkerAlt className="text-green-500 mr-1" />
                    <p className="mt-1 text-sm">
                      <strong></strong> {task?.location || "Remote"}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <FaCalendarAlt className="text-orange-500 mr-1" />
                    <p className="mt-1 text-sm">
                      <strong></strong>{" "}
                      {task?.date
                        ? new Date(task.date).toLocaleString()
                        : "Flexible"}
                    </p>
                  </div>
                  <p className="mt-1 text-sm">
                    <strong>${task?.agreedBudget} AUD</strong>
                  </p>
                  <p className="mt-1 text-gray-600 text-sm">
                    {task?.description}
                  </p>
                </div>
                <div className="flex justify-center items-center hidden  lg:block">
                  <div className="bg-gray-300 p-10 rounded-lg shadow-lg  mt-7 w-full max-w-md text-center">
                    <p className="text-5xl font-bold text-gray-800">
                      ${task?.agreedBudget}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-2">
                {task.status === "accepted" && (
                  <button
                    onClick={handleCompleteTaskClick}
                    className="bg-green-500 text-white  shadow-md  px-4 py-2 rounded"
                    disabled={taskCompleted}
                  >
                    {taskCompleted ? "Task Completed" : "Complete Task"}
                  </button>
                )}
                {
                  task.status === "completedByAssignee" && (
                    <p className="text-green-500">Task completed by you. Waiting for owner to mark as done.</p>
                  )
                }
                {
                  task.status === "completedByOwner" && (
                    <p className="text-green-500">Task completed by owner. Payment will be processed shortly.</p>
                  )
                }
              </div>

              <div className="mt-4 flex">
                <button
                  className={`mr-2 px-6 py-2 rounded-t-lg text-sm font-medium transition-all duration-300 ease-in-out ${
                    activeTab === "messages"
                      ? "bg-blue-500 text-white border-b-2 border-blue-700"
                      : "bg-gray-200 text-blue-500 hover:bg-gray-300"
                  }`}
                  onClick={() => handleTabChange("messages")}
                >
                  Messages
                </button>

                <button
                  className={`px-6 py-2 rounded-t-lg text-sm font-medium transition-all duration-300 ease-in-out ${
                    activeTab === "questions"
                      ? "bg-blue-500 text-white border-b-2 border-blue-700"
                      : "bg-gray-200 text-blue-500 hover:bg-gray-300"
                  }`}
                  onClick={() => handleTabChange("questions")}
                >
                  Questions by other taskers
                </button>
              </div>

              {activeTab === "messages" ? (
                <div className="mt-2">
                  {task?.messages.length > 0 ? (
                    task.messages.map((message) => (
                      <div
                        key={message._id}
                        className="flex items-start border-b py-2"
                      >
                        <div className="mr-2">
                        {message.sender.profileImage ? (
                            <img
                              src={message.sender.profileImage}
                              alt="Profile"
                              className="w-8 h-8 mr-1  mt-2 rounded-full"
                            />
                          ) : (

                          <FaUserCircle
                            className="text-blue-500 mt-2"
                            size={25}
                          />
                          )}
                        </div>

                        <div className="flex-grow bg-gray-100 rounded-lg p-2">
                          <p className="text-xs text-gray-500">
                            {message.sender.name} {/* Display sender name */}
                          </p>
                          <div
                            className="text-sm mb-2"
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            <p>{message.message}</p>{" "}
                            {/* Display message content */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <p className="text-sm mb-2">
                        No messages yet. Start the conversation!
                      </p>
                    </div>
                  )}
                  {
                    task.status !== "completedByOwner" && (
                      <div className="mt-2">
                    <input
                      type="text"
                      value={newMessage || ""}
                      placeholder="Send a message"
                      className="border border-gray-300 bg-white rounded p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
                      onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <button
                      onClick={() => handleSendMessage()}
                      className="bg-blue-500 text-white px-4 py-1 rounded text-sm"
                    >
                      Send
                    </button>
                  </div>
                    )
                  }
                  
                </div>
              ) : (
                <div className="mt-2">
                  {task?.questions.length > 0 ? (
                    task.questions.map((question) => (
                      <div
                        key={question._id}
                        className="flex items-start border-b py-1"
                      >
                        <div className="mr-2">
                          {question.askedBy.profileImage ? (
                            <img src={question.askedBy.profileImage} alt="Profile" className="w-8 h-8 mr-1  mt-2 rounded-full" />
                          ) : (
                          <FaUserCircle
                            className="text-blue-500 mt-2"
                            size={25} 
                          />
                          )}
                           
                        </div>
                        <div className="flex-grow bg-gray-100 rounded-lg p-2">
                          <p className="text-xs text-gray-500">
                            {question.askedBy.name}
                          </p>
                          <p className="text-sm">
                            <strong>Question: </strong> {question.question}
                          </p>
                          {question.answer && (
                            <p className="text-sm">
                              <strong>Answer: </strong> {question.answer}
                            </p>
                          )  }
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm">No questions yet.</p>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default TaskDetailsModalForAssigned;
