import React, { useEffect, useState, useContext } from "react";
import Modal from "react-modal";
import TaskCard from "./TaskCard";
import TaskDetailsModal from "./TaskDetailsModal";
import axiosInstance from "../../axiosInstance";
import { FaSpinner } from "react-icons/fa"; // Import spinner icon
import AuthContext from "../../AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const AllTasks = ({ showSignRegisterInModal }) => {
  const [tasks, setTasks] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const { isLoggedOut } = useContext(AuthContext);
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    axiosInstance
      .get("/api/tasks/getAllTasks")
      .then((response) => {
        setTasks(response.data.tasks);
        setLoading(false); // Stop loading when data is received
      })
      .catch((error) => {
        console.error("Error fetching tasks:", error);
        setError("Failed to load tasks. Please try again later."); // Set error message
        setLoading(false); // Stop loading even if there's an error
      });
  }, []);

  const openModal = async (task) => {
    //check if user is logged in or not other
    if (isLoggedOut) {
      showSignRegisterInModal();
      return;
    }

    setSelectedTaskId(task._id);
    setModalIsOpen(true);
    // try {
    //   const response = await checkIfUserHasPaymentMethodAdded();
    //   if (response.data.taskPaymentMethodAdded) {
    //     setSelectedTaskId(task._id);
    //     setModalIsOpen(true);
    //   }
    //   if (response.data.url && response.data.url !== "") {
    //     MySwal.fire({
    //       icon: "info",
    //       title: "Payment Details Required",
    //       text: "You need to add your payment details to proceed. A quick setup process will guide you through a few verification steps.",
    //       showCancelButton: true,
    //       confirmButtonText: "Add Payment",
    //       cancelButtonText: "Close",
    //     }).then((result) => {
    //       if (result.isConfirmed) {
    //         // If the user clicked "Add Payment", redirect them to the Stripe account link
    //         window.open(response.data.url, "_blank");
    //       }
    //     });
    //   }
    // } catch (error) {
    //   console.error("Unexpected error occurred:", error);
    //   MySwal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "There was an error checking your payment details. Please try again later.",
    //   });
    // }
  };

  const closeModal = () => {
    setSelectedTaskId(null);
    setModalIsOpen(false);
  };

  const checkIfUserHasPaymentMethodAdded = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/tasks/checkIfUserHasTaskPaymentMethod`
      );

      return response;
    } catch (error) {
      console.error("Unexpected error occurred:", error);
      return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center ">
      {loading ? (
        <div className="flex items-center justify-center ">
          <FaSpinner className="text-blue-500 animate-spin text-4xl" />
          <span className="text-blue-500 text-lg ml-2">Loading tasks...</span>
        </div>
      ) : error ? (
        <div className="text-red-600 text-center mt-4">{error}</div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-5xl mt-1">
          {tasks.map((task) => (
            <TaskCard key={task.id} task={task} onOpenModal={openModal} />
          ))}
        </div>
      )}

      {!loading && tasks.length === 0 && (
        <div className="text-center mt-4">
          No posted tasks yet. Please check back later.
        </div>
      )}

      {/* Modal for task details */}
      <TaskDetailsModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        taskId={selectedTaskId}
      />
    </div>
  );
};

export default AllTasks;
